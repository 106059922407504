import { useStore } from '@/store/store';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { accessToken, refreshToken } from '@/services/OAuth2';

const debug = false;

export async function loadStorage() {
    const store = useStore();
    const AT = await SecureStoragePlugin.get({ key: 'accessToken' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (AT) {
        accessToken.value = AT.value;
    }

    const RT = await SecureStoragePlugin.get({ key: 'refreshToken' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (RT) {
        refreshToken.value = RT.value;
    }

    //get login type of user
    const loginType = await SecureStoragePlugin.get({ key: 'loginType' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (loginType) {
        store.changeLoginType(loginType.value);
    }

    //get scope
    const scope = await SecureStoragePlugin.get({ key: 'scope' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (scope) {
        store.changeScope(scope.value);
    }

    //get saved lang
    const lang = await SecureStoragePlugin.get({ key: 'lang' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (lang) {
        if (lang.value == '') {
            store.changeLang('en');
        } else {
            store.changeLang(lang.value);
        }
    }

    //disable push notificaiton alert warning value
    const disableNotification = await SecureStoragePlugin.get({ key: 'disableNotification' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (disableNotification) {
        store.changeDisableNotifications(disableNotification.value);
    }

    //welcome status, standard welcome
    const welcomePopup = await SecureStoragePlugin.get({ key: 'welcomePopup' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (welcomePopup) {
        store.changeWelcomeStatus(welcomePopup.value);
    }

    //review status
    const reviewStatus = await SecureStoragePlugin.get({ key: 'reviewStatus' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (reviewStatus) {
        store.changeReviewStatus(reviewStatus.value);
    }

    //welcome status, housekeeper welcome
    const welcomePopupHousekeeper = await SecureStoragePlugin.get({ key: 'welcomePopupHousekeeper' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (welcomePopupHousekeeper) {
        store.changeWelcomeStatusHousekeeper(welcomePopupHousekeeper.value);
    }

    //widget settings
    const showInquiriesWidget = await SecureStoragePlugin.get({ key: 'showInquiriesWidget' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (showInquiriesWidget) {
        store.changeShowInquiriesWidget(showInquiriesWidget.value == 'true');
    }
    const showPendingWidget = await SecureStoragePlugin.get({ key: 'showPendingWidget' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (showPendingWidget) {
        store.changeShowPendingWidget(showPendingWidget.value == 'true');
    }
    const showArrivalsWidget = await SecureStoragePlugin.get({ key: 'showArrivalsWidget' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (showArrivalsWidget) {
        store.changeShowArrivalsWidget(showArrivalsWidget.value == 'true');
    }
    const showDeparturesWidget = await SecureStoragePlugin.get({ key: 'showDeparturesWidget' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (showDeparturesWidget) {
        store.changeShowDeparturesWidget(showDeparturesWidget.value == 'true');
    }
    const showOccupancyWidget = await SecureStoragePlugin.get({ key: 'showOccupancyWidget' }).catch((error) => {
        if (debug) {
            console.log(error);
        }
    });
    if (showOccupancyWidget) {
        store.changeShowOccupancyWidget(showOccupancyWidget.value == 'true');
    }
}

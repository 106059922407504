import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core';

const hapticsImpactHeavy = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.impact({ style: ImpactStyle.Heavy });
};

const hapticsImpactMedium = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.impact({ style: ImpactStyle.Medium });
};

const hapticsImpactLight = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.impact({ style: ImpactStyle.Light });
};

const hapticsVibrate = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.vibrate();
};

const hapticsSelectionStart = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.selectionStart();
};

const hapticsSelectionChanged = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.selectionChanged();
};

const hapticsSelectionEnd = async () => {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    await Haptics.selectionEnd();
};

export {
    hapticsImpactHeavy,
    hapticsImpactMedium,
    hapticsImpactLight,
    hapticsVibrate,
    hapticsSelectionStart,
    hapticsSelectionChanged,
    hapticsSelectionEnd,
};

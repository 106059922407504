import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { slot: "content" }
const _hoisted_2 = { slot: "content" }
const _hoisted_3 = { slot: "content" }
const _hoisted_4 = { slot: "content" }
const _hoisted_5 = {
  style: {"margin-top":"10px","display":"flex","justify-content":"center","width":"100%"},
  slot: "header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InquiriesWidget = _resolveComponent("InquiriesWidget")!
  const _component_InquiriesWidgetContent = _resolveComponent("InquiriesWidgetContent")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_PendingReservationWidget = _resolveComponent("PendingReservationWidget")!
  const _component_PendingReservationWidgetContent = _resolveComponent("PendingReservationWidgetContent")!
  const _component_ArrivalsWidget = _resolveComponent("ArrivalsWidget")!
  const _component_ArrivalsWidgetContent = _resolveComponent("ArrivalsWidgetContent")!
  const _component_DeparturesWidget = _resolveComponent("DeparturesWidget")!
  const _component_DeparturesWidgetContent = _resolveComponent("DeparturesWidgetContent")!
  const _component_OccupancyWidget = _resolveComponent("OccupancyWidget")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_accordion_group, null, {
      default: _withCtx(() => [
        (_ctx.store.showInquiriesWidget)
          ? (_openBlock(), _createBlock(_component_ion_accordion, {
              key: 0,
              value: "first"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InquiriesWidget, {
                  style: {"cursor":"pointer"},
                  slot: "header"
                }),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_InquiriesWidgetContent)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.showPendingWidget)
          ? (_openBlock(), _createBlock(_component_ion_accordion, {
              key: 1,
              value: "second"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PendingReservationWidget, {
                  style: {"cursor":"pointer"},
                  slot: "header"
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_PendingReservationWidgetContent)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.showArrivalsWidget)
          ? (_openBlock(), _createBlock(_component_ion_accordion, {
              key: 2,
              value: "third"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ArrivalsWidget, {
                  style: {"cursor":"pointer"},
                  slot: "header"
                }),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ArrivalsWidgetContent)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.showDeparturesWidget)
          ? (_openBlock(), _createBlock(_component_ion_accordion, {
              key: 3,
              value: "forth"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeparturesWidget, {
                  style: {"cursor":"pointer"},
                  slot: "header"
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_DeparturesWidgetContent)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.showOccupancyWidget)
          ? (_openBlock(), _createBlock(_component_ion_accordion, {
              key: 4,
              value: "fith"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OccupancyWidget, { slot: "header" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ion_button, {
        style: {"width":"50%"},
        color: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ModalController.widgetEditModal()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Edit Widgets")
        ]),
        _: 1
      })
    ])
  ], 64))
}
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

//save a image (JPEG) file to filesystem
async function writeImage(fullFilePath: string, data: Photo) {
    const base64Data = await readAsBase64(data);
    await Filesystem.writeFile({
        path: fullFilePath,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data: base64Data!,
        directory: Directory.Data,
    });
    return base64Data;
}

//save a text file to filesystem
async function writeText(fullFilePath: string, data: string) {
    await Filesystem.writeFile({
        path: fullFilePath,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data: data,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
    });
}

//encoding to base64 for images
async function readAsBase64(photo: Photo) {
    if (Capacitor.getPlatform() != 'web') {
        const file = await Filesystem.readFile({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            path: photo.path!,
        });
        return file.data;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await fetch(photo.webPath!);
        const blob = await response.blob();

        return (await convertBlobToBase64(blob)) as string;
    }
}

// Helper function for base64
function convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
}

//read a file from file system (CURRENTLY NOT USED)
async function readFile(fullFilePath: string) {
    const content = await Filesystem.readFile({
        path: fullFilePath,
        directory: Directory.Data,
    });

    return content;
}

// delete a file from filesystem
async function deleteFile(fullFilePath: string) {
    await Filesystem.deleteFile({
        path: fullFilePath,
        directory: Directory.Data,
    });
}

// delete a folder from filesystem and is recursive
async function deleteDirectory(fullDirectoryPath: string) {
    await Filesystem.rmdir({
        path: fullDirectoryPath,
        directory: Directory.Data,
        recursive: true,
    });
}

// returns a list of all files and folders in a given directory
async function readDirectory(directoryPath: string) {
    let fileList = [
        {
            name: '',
            data: '',
        },
    ];
    await Filesystem.readdir({
        directory: Directory.Data,
        path: directoryPath,
    }).then(
        async (result) => {
            const tmp = await loadFiles(result.files, directoryPath);
            fileList = tmp;
        },
        async (err) => {
            await createHousekeeperFilesystem();
            await Filesystem.mkdir({
                directory: Directory.Data,
                path: directoryPath,
            });
        }
    );
    return fileList;
}

// helper function for read directory
async function loadFiles(fileNames: any, directoryPath: string) {
    const fileList = [];
    for (const f of fileNames) {
        if (f.name.split('.')[0] === '') {
            //check for hidden files
        } else {
            const filePath = directoryPath + '/' + f.name;
            const fileType = filePath.split('.')[1];
            let readFile: any;
            let file: any;

            if (f.name.includes('.')) {
                if (fileType === 'jpeg') {
                    readFile = await Filesystem.readFile({
                        directory: Directory.Data,
                        path: filePath,
                    });
                    //image file
                    if (Capacitor.getPlatform() != 'web') {
                        readFile.data = `data:image/jpeg;base64,${readFile.data}`;
                    } else {
                        readFile.data = `data:image/jpeg;base64,${readFile.data}`;
                    }
                } else if (fileType === 'txt') {
                    //text file
                    readFile = await Filesystem.readFile({
                        directory: Directory.Data,
                        path: filePath,
                        encoding: Encoding.UTF8,
                    });
                    //encoding: Encoding.UTF8,
                }
                file = {
                    name: f.name,
                    data: readFile.data,
                };
            } else {
                file = {
                    name: f.name,
                    data: '',
                };
            }

            fileList.push(file);
        }
    }
    return fileList;
}

// creates the basic layout of the housekeeper filesystem if one doens't currently exist ('HOUSEKEEPER/JOBS/')
async function createHousekeeperFilesystem() {
    Filesystem.readdir({
        directory: Directory.Data,
        path: 'LODGIX',
    }).then(
        (result) => {
            Filesystem.readdir({
                directory: Directory.Data,
                path: 'LODGIX/HOUSEKEEPER',
            }).then(
                (result) => {
                    Filesystem.readdir({
                        directory: Directory.Data,
                        path: 'LODGIX/HOUSEKEEPER/JOBS',
                    }).then(
                        (result) => {
                            //return since all directory exist.
                            //return;
                        },
                        async (err) => {
                            //console.log(err)
                            await Filesystem.mkdir({
                                directory: Directory.Data,
                                path: 'LODGIX/HOUSEKEEPER/JOBS',
                            }).then((result) => {
                                //run function again
                                createHousekeeperFilesystem();
                            });
                        }
                    );
                },
                async (err) => {
                    await Filesystem.mkdir({
                        directory: Directory.Data,
                        path: 'LODGIX/HOUSEKEEPER',
                    }).then(async (err) => {
                        //run function again
                        createHousekeeperFilesystem();
                    });
                }
            );
        },
        async (err) => {
            await Filesystem.mkdir({
                directory: Directory.Data,
                path: 'LODGIX',
            }).then((result) => {
                //run function again
                createHousekeeperFilesystem();
            });
        }
    );
}

export { writeImage, writeText, deleteFile, readDirectory, createHousekeeperFilesystem, deleteDirectory, readFile };

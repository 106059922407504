import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    ref: "modalBaseLayoutRef",
    title: "Filters"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { style: {"text-align":"center"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Filter changes are applied automatically.")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFilters()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Reset Filters")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalBaseLayoutRef.confirm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Close")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.filters)
        ? (_openBlock(), _createBlock(_component_ion_list, {
            key: 0,
            lines: "full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    class: "filter-button",
                    modelValue: _ctx.filters.timeframe,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.timeframe) = $event)),
                    placeholder: "Select Timeframe",
                    label: "Timeframe"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "Yesterday" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Yesterday")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "Today" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Today")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "Last Week" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Last Week")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "This Week" }, {
                        default: _withCtx(() => [
                          _createTextVNode("This Week")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "Last Month" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Last Month")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "This Month" }, {
                        default: _withCtx(() => [
                          _createTextVNode("This Month")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "Last Year" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Last Year")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "This Year" }, {
                        default: _withCtx(() => [
                          _createTextVNode("This Year")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "Future" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Future")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    class: "filter-button",
                    modelValue: _ctx.filters.type,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.type) = $event)),
                    placeholder: "Select Type",
                    label: "Type"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Reservations")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "BLOCKED" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Blocks")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    class: "filter-button",
                    modelValue: _ctx.filters.status,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.status) = $event)),
                    placeholder: "Select Status",
                    label: "Status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("All Statuses")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "PAID" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Paid/Paid In Full")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "PAIDDEPOSIT" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Confirmed")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "NOTPAID" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Unconfirmed")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "CANCELLED" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Cancelled")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 512))
}
<template>
    <ion-card-header class="ldx-section-start">
        <ion-card-title>Property Quote</ion-card-title>
    </ion-card-header>
    <ion-list>
        <ion-item lines="full" v-if="store.reservationPropertyListQuotes.deposits != ''">
            <ion-label class="ion-text-wrap" style="text-align: left">Deposits: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.deposits)
            }}</ion-label>
        </ion-item>
        <ion-item lines="full" v-if="store.reservationPropertyListQuotes.discount != ''">
            <ion-label class="ion-text-wrap" style="text-align: left">Discounts: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.discount)
            }}</ion-label>
        </ion-item>
        <ion-item lines="full" v-if="store.reservationPropertyListQuotes.extra_guest_fee != ''">
            <ion-label class="ion-text-wrap" style="text-align: left">Extra Guest Fee: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.extra_guest_fee)
            }}</ion-label>
        </ion-item>
        <ion-item lines="full" v-if="store.reservationPropertyListQuotes.fees != ''">
            <ion-label class="ion-text-wrap" style="text-align: left">Fees: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.fees)
            }}</ion-label>
        </ion-item>
        <ion-item lines="full" v-if="store.reservationPropertyListQuotes.rental_charges != ''">
            <ion-label class="ion-text-wrap" style="text-align: left">Rental Charges: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.rental_charges)
            }}</ion-label>
        </ion-item>
        <ion-item lines="full" style="border-bottom: 2px solid black">
            <ion-label class="ion-text-wrap" style="text-align: left">Taxes: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.taxes)
            }}</ion-label>
        </ion-item>
        <ion-item lines="none">
            <ion-label class="ion-text-wrap" style="text-align: left">Total: </ion-label>
            <ion-label class="ion-text-wrap" slot="end" style="text-align: right">{{
                getDisplayMoney(store.reservationPropertyListQuotes.total)
            }}</ion-label>
        </ion-item>
    </ion-list>
</template>

<script lang="ts">
    import { useStore } from '@/store/store';
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const getDisplayMoney = (value: number) => {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: store.userCurrency,
                });
                return format.format(value);
            };

            return {
                store,
                getDisplayMoney,
            };
        },
    });
</script>

<style scoped>
    .ldx-section-start {
        padding-top: 0;
        padding-left: 0;
    }

    .content-section {
        padding-bottom: 32px;
    }
    .ldx-section-start ion-card-title {
        font-size: 20px;
    }
    .ldx-reservation-form ion-item::part(native) {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;
    }
    .ldx-reservation-form ion-label {
        margin: 0;
    }

    .ldx-reservation-form ion-icon {
        font-size: 14px;
    }

    .ldx-reservation-form .select-icon {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-reservation-form ion-select::part(placeholder),
    .ldx-reservation-form ion-select::part(text),
    .ldx-reservation-form ion-select::part(icon) {
        color: #000000;
        opacity: 1;
    }
    .ldx-reservation-form ion-select::part(icon) {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-reservation-form ion-icon.ldx-alt-icon {
        background: var(--ion-color-primary);
        color: #ffffff;
        padding: 0.5rem;
        font-size: 15px;
        border-radius: 5px;
        margin: 0;
    }
</style>

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { defineStore } from 'pinia';

export const useStore = defineStore('useStore', {
    state: () => ({
        Lang: 'en',
        LoginType: '',
        RequireLogin: true,
        Id: '',
        SubscriberId: '',
        UserFirstName: '',
        UserLastName: '',
        UserEmail: '',
        UserCurrency: '',
        UserCurrencySymbol: '',
        FirstOpenApp: '0', //0=has not been opened, 1=has been opened
        CompanyEmail: '',
        CompanyName: '',
        CompanyLogo: '',
        CompanyAddress: '',
        CompanyWebsite: '',
        CompanyPhone: '',

        PushNotificationToken: '',
        PushNotificationUnreadCount: 0,
        PushNotificationList: [
            {
                id: -1,
                date: '',
                subject: '',
                body: '',
                manual: true,
                is_read: false,
            },
        ],
        PushNotificationOpenId: '',

        //reservation & block storage
        ReservationFirstName: '',
        ReservationLastName: '',
        ReservationEmail: '',
        ReservationGuestId: '',
        ReservationStartDate: '',
        ReservationEndDate: '',
        ReservationSelectedProperty: '',
        ReservationSelectedPropertyId: '',
        ReservationAdults: '',
        ReservationChildren: '',
        ReservationPets: '',
        ReservationDateEntered: '',
        ReservationType: '',
        ReservationTaxExemption: '',
        ReservationConfirmationAmount: '',
        ReservationThumbnailUrl: '',
        ReservationTotalWithDeposits: '',
        ReservationCustomAmount: '',

        ReservationInvoiceId: '',
        ReservationSetupIntentClientSecret: '',
        ReservationStripePublishableKey: '',
        ReservationStripeAccount: '',
        ReservationStripeIsCard: false,

        ReservationPropertyListQuotes: {} as any,
        ReservationPropertyListHasOwner: '',
        ReservationFromCalendarTape: false,

        ReservationBillingAddress: '',
        ReservationBillingCity: '',
        ReservationBillingState: '-1',
        ReservationBillingZip: '',
        ReservationBillingCountry: '-1',
        ReservationBillingCountryList: [{ id: -1, name: '', code: '' }],
        ReservationBillingStateList: [{ id: -1, name: '', code: '' }],

        //widget data
        PendingAmount: '',
        PendingDataCount: 0,
        PendingDataArray: [
            {
                booking_method_icon_url: '',
                booking_method_name: '',
                current_balance: 0,
                display_booking_method_name: '',
                entered: '',
                from_date: '',
                has_services: false,
                has_travel_insurance: false,
                id: 0,
                property_name: '',
                sign_status: '',
                signed_agreement: '',
                to_date: '',
                user: {
                    email: '',
                    id: 0,
                    short_name: '',
                    status: '',
                },
            },
        ],

        ArrivalAmount: '',
        ArrivalDataCount: 0,
        ArrivalDataArray: [
            {
                check_in: '',
                check_out: '',
                from_date: '',
                id: 0,
                is_early_check_in: false,
                is_late_check_out: false,
                property_name: '',
                reservation: {
                    guest_name: '',
                    has_pets: false,
                    has_services: false,
                    has_travel_insurance: false,
                    id: 0,
                    is_blocked: false,
                    notes: '',
                    sign_status: '',
                    signed_agreement: null,
                    state: {
                        id: 0,
                        name: '',
                        sysname: '',
                    },
                    user: {
                        address: {
                            address1: '',
                            address2: null,
                            city: '',
                            country: {
                                code: '',
                                id: 0,
                                name: '',
                            },
                            fax: null,
                            latitude: null,
                            longitude: null,
                            phone: '',
                            province: null,
                            short_name: '',
                            state: {
                                code: '',
                                id: 0,
                                name: '',
                            },
                            work_phone: null,
                            work_phone_ext: null,
                            zip: '',
                        },
                        email: '',
                        id: 0,
                        short_name: '',
                        status: null,
                    },
                },
                to_date: '',
            },
        ],

        DepartureAmount: '',
        DepartureDataCount: 0,
        DepartureDataArray: [
            {
                check_in: '',
                check_out: '',
                from_date: '',
                id: 0,
                is_early_check_in: false,
                is_late_check_out: false,
                property_name: '',
                reservation: {
                    guest_name: '',
                    has_pets: false,
                    has_services: false,
                    has_travel_insurance: false,
                    id: 0,
                    is_blocked: false,
                    notes: '',
                    sign_status: '',
                    signed_agreement: null,
                    state: {
                        id: 0,
                        name: '',
                        sysname: '',
                    },
                    user: {
                        address: {
                            address1: '',
                            address2: null,
                            city: '',
                            country: {
                                code: '',
                                id: 0,
                                name: '',
                            },
                            fax: null,
                            latitude: null,
                            longitude: null,
                            phone: '',
                            province: null,
                            short_name: '',
                            state: {
                                code: '',
                                id: 0,
                                name: '',
                            },
                            work_phone: null,
                            work_phone_ext: null,
                            zip: '',
                        },
                        email: '',
                        id: 0,
                        short_name: '',
                        status: null,
                    },
                },
                to_date: '',
            },
        ],

        InquiryDataCount: 0,
        InquiryDataArray: [],

        OccupancyAmount: '',
        ActivePage: '/home',
        DisableNotifications: 'no',
        WelcomeStatus: '',
        ReviewStatus: '',
        WelcomeStatusHousekeeper: '',
        CalendarTapeOptions: {},

        IsQuoteOnly: false,

        //chat data
        ChatUserId: '',
        WebsocketToken: '',
        WebsocketUrl: '',
        InboxPermissions: {},

        Scope: '',

        //widget settings
        showInquiriesWidget: true,
        showPendingWidget: true,
        showArrivalsWidget: true,
        showDeparturesWidget: true,
        showOccupancyWidget: true,

        //work order data
        WorkOrderList: [] as any[],
        WorkOrderListTotal: 0,
        WorkOrderJobTypes: [] as any[],
        WorkOrderVendors: [] as any[],
        WorkOrderExpenseAccoutns: [] as any[],
        WorkOrderFormOptions: {} as any,
        WorkOrderFormProperties: [] as any[],
        WorkOrderTeamMembers: [] as any[],
        WorkOrderPermissions: {
            can_add: false,
            can_delete: false,
            has_add_access: false,
            has_base_work_order_access: false,
            has_config_access: false,
            has_delete_access: false,
            has_modify_access: false,
            is_close_disabled: false,
            is_modify_disabled: false,
            is_subscriber: false,
        },

        // saved chat message and conversat ids from deep link on first login
        branchConversationId: '',
        branchMessageId: '',
        debug: process.env.VUE_APP_DEBUG === 'true',
    }),

    getters: {
        lang: (state) => state.Lang,
        loginType: (state) => state.LoginType,
        id: (state) => state.Id,
        subscriberId: (state) => state.SubscriberId,
        userFirstName: (state) => state.UserFirstName,
        userLastName: (state) => state.UserLastName,
        userEmail: (state) => state.UserEmail,
        userCurrency: (state) => state.UserCurrency,
        userCurrencySymbol: (state) => state.UserCurrencySymbol,
        requireLogin: (state) => state.RequireLogin,
        firstOpenApp: (state) => state.FirstOpenApp,
        companyEmail: (state) => state.CompanyEmail,
        companyName: (state) => state.CompanyName,
        companyLogo: (state) => state.CompanyLogo,
        companyAddress: (state) => state.CompanyAddress,
        companyWebsite: (state) => state.CompanyWebsite,
        companyPhone: (state) => state.CompanyPhone,
        pushNotificationToken: (state) => state.PushNotificationToken,
        pushNotificationUnreadCount: (state) => state.PushNotificationUnreadCount,
        pushNotificationList: (state) => state.PushNotificationList,
        pushNotificationOpenId: (state) => state.PushNotificationOpenId,

        reservationFirstName: (state) => state.ReservationFirstName,
        reservationLastName: (state) => state.ReservationLastName,
        reservationEmail: (state) => state.ReservationEmail,
        reservationGuestId: (state) => state.ReservationGuestId,
        reservationStartDate: (state) => state.ReservationStartDate,
        reservationEndDate: (state) => state.ReservationEndDate,
        reservationSelectedProperty: (state) => state.ReservationSelectedProperty,
        reservationSelectedPropertyId: (state) => state.ReservationSelectedPropertyId,
        reservationAdults: (state) => state.ReservationAdults,
        reservationChildren: (state) => state.ReservationChildren,
        reservationPets: (state) => state.ReservationPets,
        reservationDateEntered: (state) => state.ReservationDateEntered,
        reservationType: (state) => state.ReservationType,
        reservationTaxExemption: (state) => state.ReservationTaxExemption,
        reservationConfirmationAmount: (state) => state.ReservationConfirmationAmount,
        reservationThumbnailUrl: (state) => state.ReservationThumbnailUrl,
        reservationTotalWithDeposits: (state) => state.ReservationTotalWithDeposits,
        reservationCustomAmount: (state) => state.ReservationCustomAmount,

        reservationInvoiceId: (state) => state.ReservationInvoiceId,
        reservationSetupIntentClientSecret: (state) => state.ReservationSetupIntentClientSecret,
        reservationStripePublishableKey: (state) => state.ReservationStripePublishableKey,
        reservationStripeAccount: (state) => state.ReservationStripeAccount,
        reservationStripeIsCard: (state) => state.ReservationStripeIsCard,

        reservationPropertyListQuotes: (state) => state.ReservationPropertyListQuotes,
        reservationPropertyListHasOwner: (state) => state.ReservationPropertyListHasOwner,
        reservationFromCalendarTape: (state) => state.ReservationFromCalendarTape,

        reservationBillingAddress: (state) => state.ReservationBillingAddress,
        reservationBillingCity: (state) => state.ReservationBillingCity,
        reservationBillingState: (state) => state.ReservationBillingState,
        reservationBillingZip: (state) => state.ReservationBillingZip,
        reservationBillingCountry: (state) => state.ReservationBillingCountry,
        reservationBillingCountryList: (state) => state.ReservationBillingCountryList,
        reservationBillingStateList: (state) => state.ReservationBillingStateList,

        pendingDataArray: (state) => state.PendingDataArray,
        pendingDataCount: (state) => state.PendingDataCount,
        pendingAmount: (state) => state.PendingAmount,
        arrivalDataArray: (state) => state.ArrivalDataArray,
        arrivalDataCount: (state) => state.ArrivalDataCount,
        arrivalAmount: (state) => state.ArrivalAmount,
        departureDataArray: (state) => state.DepartureDataArray,
        departureDataCount: (state) => state.DepartureDataCount,
        departureAmount: (state) => state.DepartureAmount,
        occupancyAmount: (state) => state.OccupancyAmount,
        activePage: (state) => state.ActivePage,
        disableNotifications: (state) => state.DisableNotifications,
        welcomeStatus: (state) => state.WelcomeStatus,
        reviewStatus: (state) => state.ReviewStatus,
        welcomeStatusHousekeeper: (state) => state.WelcomeStatusHousekeeper,
        calendarTapeOptions: (state) => state.CalendarTapeOptions,
        chatUserId: (state) => state.ChatUserId,
        websocketToken: (state) => state.WebsocketToken,
        websocketUrl: (state) => state.WebsocketUrl,
        inboxPermissions: (state) => state.InboxPermissions,
        isQuoteOnly: (state) => state.IsQuoteOnly,
        scope: (state) => state.Scope,
        inquiryDataCount: (state) => state.InquiryDataCount,
        inquiryDataArray: (state) => state.InquiryDataArray,

        // work orders
        workOrderList: (state) => state.WorkOrderList,
        workOrderListTotal: (state) => state.WorkOrderListTotal,
        workOrderJobTypes: (state) => state.WorkOrderJobTypes,
        workOrderVendors: (state) => state.WorkOrderVendors,
        workOrderExpenseAccounts: (state) => state.WorkOrderExpenseAccoutns,
        workOrderFormOptions: (state) => state.WorkOrderFormOptions,
        workOrderFormProperties: (state) => state.WorkOrderFormProperties,
        workOrderTeamMembers: (state) => state.WorkOrderTeamMembers,
        workOrderPermissions: (state) => state.WorkOrderPermissions,
    },

    actions: {
        changeLang(newLang: string) {
            this.Lang = newLang;
            SecureStoragePlugin.set({
                key: 'lang',
                value: newLang,
            });
        },
        changeLoginType(newLoginType: string) {
            this.LoginType = newLoginType;
        },
        changeId(newId: string) {
            this.Id = newId;
        },
        changeSubId(newId: string) {
            this.SubscriberId = newId;
        },
        changeName(newName: string) {
            this.UserFirstName = newName;
        },
        changeLastName(newLastName: string) {
            this.UserLastName = newLastName;
        },
        changeEmail(newEmail: string) {
            this.UserEmail = newEmail;
        },
        changeCurrency(newCurrency: string) {
            this.UserCurrency = newCurrency;
        },
        changeCurrencySymbol(newCurrencySymbol: string) {
            this.UserCurrencySymbol = newCurrencySymbol;
        },
        changeFirstOpenApp(newFirstOpenApp: string) {
            this.FirstOpenApp = newFirstOpenApp;
        },
        changeCompanyEmail(newCompanyEmail: string) {
            this.CompanyEmail = newCompanyEmail;
        },
        changeCompanyName(newCompanyName: string) {
            this.CompanyName = newCompanyName;
        },
        changeCompanyLogo(newCompanyLogo: string) {
            this.CompanyLogo = newCompanyLogo;
        },
        changeCompanyAddress(newCompanyAddress: string) {
            this.CompanyAddress = newCompanyAddress;
        },
        changeCompanyWebsite(newCompanyWebsite: string) {
            this.CompanyWebsite = newCompanyWebsite;
        },
        changeCompanyPhone(newCompanyPhone: string) {
            this.CompanyPhone = newCompanyPhone;
        },

        //notification storage
        changePushNotificationToken(newPushNotificationToken: string) {
            this.PushNotificationToken = newPushNotificationToken;
        },
        changepushNotificationUnreadCount(newNotificationUnreadCount: number) {
            this.PushNotificationUnreadCount = newNotificationUnreadCount;
        },
        setPushNotificationList(newList: any) {
            this.PushNotificationList = newList;
        },
        removePushNotification(id: number) {
            for (let i = 0; i < this.PushNotificationList.length; i++) {
                if (this.PushNotificationList[i].id == id) {
                    this.PushNotificationList.splice(i, 1);
                    return;
                }
            }
        },
        changePushNotificationOpenId(newId: string) {
            this.PushNotificationOpenId = newId;
        },

        //reservation creation
        changeReservationFirstName(reservationFirstName: string) {
            this.ReservationFirstName = reservationFirstName;
        },
        changeReservationLastName(reservationLastName: string) {
            this.ReservationLastName = reservationLastName;
        },
        changeReservationEmail(reservationEmail: string) {
            this.ReservationEmail = reservationEmail;
        },
        changeReservationGuestId(reservationGuestId: string) {
            this.ReservationGuestId = reservationGuestId;
        },
        changeReservationStartDate(reservationStartDate: string) {
            this.ReservationStartDate = reservationStartDate;
        },
        changeReservationEndDate(reservationEndDate: string) {
            this.ReservationEndDate = reservationEndDate;
        },
        changeReservationSelectedProperty(reservationSelectedProperty: string) {
            this.ReservationSelectedProperty = reservationSelectedProperty;
        },
        changeReservationSelectedPropertyId(reservationSelectedPropertyId: string) {
            this.ReservationSelectedPropertyId = reservationSelectedPropertyId;
        },
        changeReservationAdults(reservationAdults: string) {
            this.ReservationAdults = reservationAdults;
        },
        changeReservationChildren(reservationChildren: string) {
            this.ReservationChildren = reservationChildren;
        },
        changeReservationPets(reservationPets: string) {
            this.ReservationPets = reservationPets;
        },
        changeReservationDateEntered(reservationDateEntered: string) {
            this.ReservationDateEntered = reservationDateEntered;
        },
        changeReservationReservationType(reservationReservationType: string) {
            this.ReservationType = reservationReservationType;
        },
        changeReservationTaxExemption(reservationTaxExemption: string) {
            this.ReservationTaxExemption = reservationTaxExemption;
        },
        changeReservationConfirmationAmount(reservationConfirmationAmount: string) {
            this.ReservationConfirmationAmount = reservationConfirmationAmount;
        },
        changeReservationThumbnailUrl(reservationThumbnailUrl: string) {
            this.ReservationThumbnailUrl = reservationThumbnailUrl;
        },

        changeReservationTotalWithDeposits(newReservationTotalWithDeposits: string) {
            this.ReservationTotalWithDeposits = newReservationTotalWithDeposits;
        },
        changeReservationCustomAmount(newReservationCustomAmount: string) {
            this.ReservationCustomAmount = newReservationCustomAmount;
        },

        changeReservationInvoiceId(reservationInvoiceId: string) {
            this.ReservationInvoiceId = reservationInvoiceId;
        },
        changeReservationSetupIntentClientSecret(reservationSetupIntentClientSecret: string) {
            this.ReservationSetupIntentClientSecret = reservationSetupIntentClientSecret;
        },
        changeReservationStripePublishableKey(reservationStripePublishableKey: string) {
            this.ReservationStripePublishableKey = reservationStripePublishableKey;
        },
        changeReservationStripeAccount(reservationStripeAccount: string) {
            this.ReservationStripeAccount = reservationStripeAccount;
        },
        changeReservationStripeIsCard(newReservationStripeIsCard: boolean) {
            this.ReservationStripeIsCard = newReservationStripeIsCard;
        },

        changeReservationPropertyListQuotes(newReservationPropertyListQuotes: string) {
            this.ReservationPropertyListQuotes = newReservationPropertyListQuotes;
        },
        changeReservationPropertyListHasOwner(newReservationPropertyListHasOwner: string) {
            this.ReservationPropertyListHasOwner = newReservationPropertyListHasOwner;
        },
        changeReservationFromCalendarTape(newReservationFromCalendarTape: any) {
            this.ReservationFromCalendarTape = newReservationFromCalendarTape;
        },

        changeReservationBillingAddress(newAddress: string) {
            this.ReservationBillingAddress = newAddress;
        },
        changeReservationBillingCity(newCity: string) {
            this.ReservationBillingCity = newCity;
        },
        changeReservationBillingState(newState: string) {
            this.ReservationBillingState = newState;
        },
        changeReservationBillingZip(newZip: string) {
            this.ReservationBillingZip = newZip;
        },
        changeReservationBillingCountry(newCountry: string) {
            this.ReservationBillingCountry = newCountry;
        },
        changeReservationBillingCountryList(newCountryList: any) {
            this.ReservationBillingCountryList = newCountryList;
        },
        changeReservationBillingStateList(newStateList: any) {
            this.ReservationBillingStateList = newStateList;
        },

        //widget data
        changePendingAmount(newAmount: string) {
            this.PendingAmount = newAmount;
        },
        changePendingDataCount(newCount: number) {
            this.PendingDataCount = newCount;
        },
        changePendingDataArray(newArray: any) {
            this.PendingDataArray = newArray;
        },
        changeArrivalAmount(newAmount: string) {
            this.ArrivalAmount = newAmount;
        },
        changeArrivalDataCount(newCount: number) {
            this.ArrivalDataCount = newCount;
        },
        changeArrivalDataArray(newArray: any) {
            this.ArrivalDataArray = newArray;
        },
        changeDepartureAmount(newAmount: string) {
            this.DepartureAmount = newAmount;
        },
        changeDepartureDataCount(newCount: number) {
            this.DepartureDataCount = newCount;
        },
        changeDepartureDataArray(newArray: any) {
            this.DepartureDataArray = newArray;
        },
        changeOccupancyAmount(newAmount: string) {
            this.OccupancyAmount = newAmount;
        },
        changeActivePage(newPage: string) {
            this.ActivePage = newPage;
        },
        changeDisableNotifications(newValue: string) {
            SecureStoragePlugin.set({ key: 'disableNotification', value: newValue });
            this.DisableNotifications = newValue;
        },
        changeWelcomeStatus(newValue: string) {
            SecureStoragePlugin.set({ key: 'welcomePopup', value: newValue });
            this.WelcomeStatus = newValue;
        },
        changeReviewStatus(newValue: string) {
            SecureStoragePlugin.set({ key: 'reviewStatus', value: newValue });
            this.ReviewStatus = newValue;
        },
        changeWelcomeStatusHousekeeper(newValue: string) {
            SecureStoragePlugin.set({ key: 'welcomePopupHousekeeper', value: newValue });
            this.WelcomeStatusHousekeeper = newValue;
        },

        changeIsQuoteOnly(newValue: boolean) {
            this.IsQuoteOnly = newValue;
        },

        //chat data
        changeChatUserId(userId: string) {
            this.ChatUserId = userId;
        },
        changeWebsocketToken(websocketToken: string) {
            this.WebsocketToken = websocketToken;
        },
        changeWebsocketUrl(websocketUrl: string) {
            this.WebsocketUrl = websocketUrl;
        },
        changeCalendarTapeOptions(newOptions: string) {
            this.CalendarTapeOptions = newOptions;
        },
        changeInboxPermissions(InboxPermissions: any) {
            this.InboxPermissions = InboxPermissions;
        },
        changeScope(newValue: string) {
            this.Scope = newValue;
            SecureStoragePlugin.set({ key: 'scope', value: newValue });
        },
        changeInquiryDataCount(newValue: number) {
            this.InquiryDataCount = newValue;
        },
        changeInquiryDataArray(newValue: any) {
            this.InquiryDataArray = newValue;
        },

        //widget settings
        changeShowInquiriesWidget(newValue: boolean) {
            this.showInquiriesWidget = newValue;
            SecureStoragePlugin.set({ key: 'showInquiriesWidget', value: newValue.toString() });
        },
        changeShowPendingWidget(newValue: boolean) {
            this.showPendingWidget = newValue;
            SecureStoragePlugin.set({ key: 'showPendingWidget', value: newValue.toString() });
        },
        changeShowArrivalsWidget(newValue: boolean) {
            this.showArrivalsWidget = newValue;
            SecureStoragePlugin.set({ key: 'showArrivalsWidget', value: newValue.toString() });
        },
        changeShowDeparturesWidget(newValue: boolean) {
            this.showDeparturesWidget = newValue;
            SecureStoragePlugin.set({ key: 'showDeparturesWidget', value: newValue.toString() });
        },
        changeShowOccupancyWidget(newValue: boolean) {
            this.showOccupancyWidget = newValue;
            SecureStoragePlugin.set({ key: 'showOccupancyWidget', value: newValue.toString() });
        },

        // work orders
        changeWorkOrderList(newValue: any) {
            this.WorkOrderList = newValue;
        },
        changeWorkOrderListTotal(newValue: number) {
            this.WorkOrderListTotal = newValue;
        },
        changeWorkOrderJobTypes(newValue: any) {
            this.WorkOrderJobTypes = newValue;
        },
        changeWorkOrderVendors(newValue: any) {
            this.WorkOrderVendors = newValue;
        },
        changeWorkOrderExpenseAccounts(newValue: any) {
            this.WorkOrderExpenseAccoutns = newValue;
        },
        changeWorkOrderFormOptions(newValue: any) {
            this.WorkOrderFormOptions = newValue;
        },
        changeWorkOrderFormProperties(newValue: any) {
            this.WorkOrderFormProperties = newValue;
        },
        changeWorkOrderTeamMembers(newValue: any) {
            this.WorkOrderTeamMembers = newValue;
        },
        changeWorkOrderPermissions(newValue: any) {
            this.WorkOrderPermissions = newValue;
        },
    },

    persist: {
        storage: sessionStorage,
    },
});

<template>
    <div class="content-section">
        <ion-card-header class="ldx-section-start">
            <ion-card-title>Guest Information</ion-card-title>
        </ion-card-header>
        <ion-list class="ldx-reservation-form">
            <ion-item lines="none" @click="guestModal" style="cursor: pointer">
                <ion-label
                    v-if="FirstName == '' || LastName == '' || Email == '' || !validateEmail(Email)"
                    style="color: red"
                    >* Select Guest</ion-label
                >
                <ion-label v-if="FirstName != '' && LastName != '' && Email != '' && validateEmail(Email)"
                    >{{ FirstName }} {{ LastName }}</ion-label
                >
                <ion-icon
                    v-if="FirstName == '' || LastName == '' || Email == '' || !validateEmail(Email)"
                    :icon="searchOutline"
                    slot="end"
                ></ion-icon>
                <ion-icon
                    v-if="FirstName != '' && LastName != '' && Email != '' && validateEmail(Email)"
                    :icon="closeCircleOutline"
                    slot="end"
                ></ion-icon>
            </ion-item>
            <ion-item lines="none">
                <ion-select
                    :interface-options="adultSelectOptions"
                    interface="alert"
                    v-model="Adults"
                    placeholder="1 Adult(s)"
                >
                    <ion-select-option v-for="n in 50" :key="n" :value="n">{{ n }} Adult(s)</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item lines="none">
                <ion-select
                    :interface-options="childrenSelectOptions"
                    interface="alert"
                    v-model="Children"
                    placeholder="0 Children"
                >
                    <ion-select-option v-for="n in 51" :key="n" :value="n - 1">{{ n - 1 }} Children</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item lines="none">
                <ion-select
                    :interface-options="petsSelectOptions"
                    interface="alert"
                    v-model="Pets"
                    placeholder="0 Pet(s)"
                >
                    <ion-select-option v-for="n in 3" :key="n" :value="n - 1">{{ n - 1 }} Pet(s)</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-list>
        {{ syncData() }}
    </div>
</template>

<script lang="ts">
    import { searchOutline, closeCircleOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import { defineComponent, ref, watch } from 'vue';
    import CommonFunctions from '@/services/AppFunctions';
    import ModalController from '@/services/ModalController';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const adultSelectOptions = {
                header: 'Number of Adults',
                subHeader: '',
                message: '',
                translucent: true,
            };

            const childrenSelectOptions = {
                header: 'Number of Children',
                subHeader: '',
                message: '',
                translucent: true,
            };

            const petsSelectOptions = {
                header: 'Number of Pets',
                subHeader: '',
                message: '',
                translucent: true,
            };

            const FirstName = ref(store.reservationFirstName);
            const LastName = ref(store.reservationLastName);
            const Email = ref(store.reservationEmail);
            const Adults = ref(parseInt(store.reservationAdults));
            const Children = ref(parseInt(store.reservationChildren));
            const Pets = ref(parseInt(store.reservationPets));

            const clearData = () => {
                FirstName.value = '';
                LastName.value = '';
                Email.value = '';
                Adults.value = 1;
                Children.value = 0;
                Pets.value = 0;
                store.changeReservationFirstName('');
                store.changeReservationLastName('');
                store.changeReservationEmail('');
                store.changeReservationAdults('1');
                store.changeReservationChildren('0');
                store.changeReservationPets('0');
            };

            const guestModal = async () => {
                await ModalController.guestModal();
            };

            const validateEmail = (email: string) => {
                var isRealEmail = CommonFunctions.validateEmail(email);
                return isRealEmail;
            };

            const syncData = () => {
                FirstName.value = store.reservationFirstName;
                LastName.value = store.reservationLastName;
                Email.value = store.reservationEmail;
                Adults.value = parseInt(store.reservationAdults);
                Children.value = parseInt(store.reservationChildren);
                Pets.value = parseInt(store.reservationPets);
            };

            watch(
                () => Pets.value,
                () => {
                    var tmpPets = '0';
                    if (Pets.value != null) {
                        tmpPets = Pets.value.toString();
                    }
                    store.changeReservationPets(tmpPets);
                }
            );
            watch(
                () => Adults.value,
                () => {
                    var tmpAdults = '1';
                    if (Adults.value != null) {
                        tmpAdults = Adults.value.toString();
                    }
                    store.changeReservationAdults(tmpAdults);
                }
            );
            watch(
                () => Children.value,
                () => {
                    var tmpChildren = '0';
                    if (Children.value != null) {
                        tmpChildren = Children.value.toString();
                    }
                    store.changeReservationChildren(tmpChildren);
                }
            );

            return {
                adultSelectOptions,
                childrenSelectOptions,
                petsSelectOptions,
                closeCircleOutline,
                searchOutline,
                FirstName,
                LastName,
                Email,
                Adults,
                Children,
                Pets,
                clearData,
                guestModal,
                validateEmail,
                syncData,
            };
        },
    });
</script>

<style scoped>
    .ldx-section-start {
        padding-top: 0;
        padding-left: 0;
    }

    .ldx-section-start ion-card-title {
        font-size: 20px;
    }
    .ldx-reservation-form ion-item::part(native) {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;
        height: 44px;
    }

    .ldx-reservation-form ion-select::part(placeholder),
    .ldx-reservation-form ion-select::part(text),
    .ldx-reservation-form ion-select::part(icon) {
        color: #000000;
        opacity: 1;
    }
    .ldx-reservation-form ion-select::part(icon) {
        right: 0rem;
        position: absolute;
    }

    .ldx-reservation-form ion-icon.ldx-alt-icon {
        background: var(--ion-color-primary);
        color: #ffffff;
        padding: 0.5rem;
        font-size: 15px;
        border-radius: 5px;
        margin: 0;
    }
</style>

<template>
    <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33.2608 1.46155H2.73923C1.77868 1.46155 1 2.24023 1 3.20078V23.2608C1 24.2213 1.77868 25 2.73923 25H33.2608C34.2213 25 35 24.2213 35 23.2608V3.20078C35 2.24023 34.2213 1.46155 33.2608 1.46155Z"
            stroke="#00B76A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M23.8584 8H12.1415C11.0437 8 10.1538 8.88992 10.1538 9.98769V16.4738C10.1538 17.5716 11.0437 18.4615 12.1415 18.4615H23.8584C24.9562 18.4615 25.8461 17.5716 25.8461 16.4738V9.98769C25.8461 8.88992 24.9562 8 23.8584 8Z"
            stroke="#00B76A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.4615 11.9231H24.5385"
            stroke="#00B76A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M24.5385 31.5385H11.4615L12.7692 25H23.2308L24.5385 31.5385Z"
            stroke="#00B76A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.84607 31.5385H27.1538"
            stroke="#00B76A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

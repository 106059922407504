import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyComponent = _resolveComponent("SurveyComponent")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Housekeeper Checklist" }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cancel")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_SurveyComponent, {
        model: _ctx.survey,
        style: {"width":"100%","height":"100%"}
      }, null, 8, ["model"])
    ]),
    _: 1
  }))
}
<template>
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.9999 31.6666C24.824 31.6666 31.1666 25.324 31.1666 17.5C31.1666 9.67595 24.824 3.33331 16.9999 3.33331C9.17588 3.33331 2.83325 9.67595 2.83325 17.5C2.83325 25.324 9.17588 31.6666 16.9999 31.6666Z"
            stroke="#FF5D5D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M21.25 13.25H12.75V21.75H21.25V13.25Z"
            stroke="#FF5D5D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

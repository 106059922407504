import { hapticsImpactHeavy } from '@/services/Haptics';
import { alertController } from '@ionic/vue';

const basicAlert = async (title: string, subTitle: string, message: string) => {
    hapticsImpactHeavy();
    title = title == '' ? 'Alert' : title;
    const alert = await alertController.create({
        cssClass: 'alertClass',
        header: title,
        subHeader: subTitle,
        message: message,
        buttons: ['OK'],
    });

    await alert.present();
};

const advancedAlert = async (title: string, subTitle: string, message: string, buttons: any) => {
    hapticsImpactHeavy();
    title = title == '' ? 'Alert' : title;
    const alert = await alertController.create({
        cssClass: 'alertClass',
        header: title,
        subHeader: subTitle,
        message: message,
        buttons: buttons,
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

const basicInputAlert = async (
    title: string,
    subTitle: string,
    message: string,
    inputPlaceholder: string,
    inputValue: string
) => {
    hapticsImpactHeavy();
    title = title == '' ? 'Alert' : title;
    const alert = await alertController.create({
        cssClass: 'alertClass',
        header: title,
        subHeader: subTitle,
        message: message,
        inputs: [
            {
                name: 'input',
                type: 'textarea',
                placeholder: inputPlaceholder || 'Enter text...',
                value: inputValue || '',
            },
        ],
        buttons: [
            {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
            },
            {
                text: 'Ok',
                role: 'confirm',
            },
        ],
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

const advancedInputAlert = async (title: string, subTitle: string, message: string, inputs: any, buttons: any) => {
    hapticsImpactHeavy();
    title = title == '' ? 'Alert' : title;
    const alert = await alertController.create({
        cssClass: 'alertClass',
        header: title,
        subHeader: subTitle,
        message: message,
        inputs: inputs,
        buttons: buttons,
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export { basicAlert, advancedAlert, basicInputAlert, advancedInputAlert };

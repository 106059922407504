<template>
    <ion-accordion-group>
        <ion-accordion value="first" v-if="store.showInquiriesWidget">
            <InquiriesWidget style="cursor: pointer" slot="header"></InquiriesWidget>
            <div slot="content">
                <InquiriesWidgetContent></InquiriesWidgetContent>
            </div>
        </ion-accordion>
        <ion-accordion value="second" v-if="store.showPendingWidget">
            <PendingReservationWidget style="cursor: pointer" slot="header"></PendingReservationWidget>
            <div slot="content">
                <PendingReservationWidgetContent></PendingReservationWidgetContent>
            </div>
        </ion-accordion>
        <ion-accordion value="third" v-if="store.showArrivalsWidget">
            <ArrivalsWidget style="cursor: pointer" slot="header"></ArrivalsWidget>
            <div slot="content">
                <ArrivalsWidgetContent></ArrivalsWidgetContent>
            </div>
        </ion-accordion>
        <ion-accordion value="forth" v-if="store.showDeparturesWidget">
            <DeparturesWidget style="cursor: pointer" slot="header"></DeparturesWidget>
            <div slot="content">
                <DeparturesWidgetContent></DeparturesWidgetContent>
            </div>
        </ion-accordion>
        <ion-accordion value="fith" v-if="store.showOccupancyWidget">
            <OccupancyWidget slot="header"></OccupancyWidget>
        </ion-accordion>
    </ion-accordion-group>
    <div style="margin-top: 10px; display: flex; justify-content: center; width: 100%" slot="header">
        <ion-button style="width: 50%" color="primary" @click="ModalController.widgetEditModal()"
            >Edit Widgets</ion-button
        >
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import PendingReservationWidget from '@/components/widgets/pendingReservationWidget.vue';
    import ArrivalsWidget from '@/components/widgets/arrivalsWidget.vue';
    import DeparturesWidget from '@/components/widgets/departuresWidget.vue';
    import OccupancyWidget from '@/components/widgets/occupancyWidget.vue';
    import ArrivalsWidgetContent from '@/components/widgets/arrivalsWidgetContent.vue';
    import PendingReservationWidgetContent from '@/components/widgets/pendingReservationWidgetContent.vue';
    import DeparturesWidgetContent from '@/components/widgets/departuresWidgetContent.vue';
    import InquiriesWidget from '../widgets/inquiriesWidget.vue';
    import InquiriesWidgetContent from '../widgets/inquiriesWidgetContent.vue';
    import { useStore } from '@/store/store';
    import ModalController from '@/services/ModalController';

    export default defineComponent({
        components: {
            ArrivalsWidget,
            PendingReservationWidget,
            DeparturesWidget,
            OccupancyWidget,
            ArrivalsWidgetContent,
            PendingReservationWidgetContent,
            DeparturesWidgetContent,
            InquiriesWidget,
            InquiriesWidgetContent,
        },
        setup() {
            const store = useStore();

            return {
                store,
                ModalController,
            };
        },
    });
</script>

<style scoped></style>

import { useStore } from '@/store/store';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import AppFunctions from './AppFunctions';
import { Intercom } from '@capacitor-community/intercom';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import ModalController from '@/services/ModalController';
import { infoToast } from './Toast';
import { accessToken } from './OAuth2';
import { SplashScreen } from '@capacitor/splash-screen';

function setupFCM() {
    const store = useStore();
    if (Capacitor.getPlatform() === 'web') {
        return;
    }

    if (store.PushNotificationToken != '') {
        return;
    }

    if (accessToken.value) {
        // Request permission to use push notifications
        registerNotifications();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', async (token: Token) => {
            store.changePushNotificationToken(token.value);
            //alert('Push registration success, token: ' + token.value);
        });

        PushNotifications.addListener('registrationError', (error: any) => {
            //alert('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            //console.log('Push received: ' + JSON.stringify(notification));
            if (store.activePage != '/unifiedInbox') {
                infoToast('Alert: New Notification!');
            }
            //PushNotifications.removeAllDeliveredNotifications();
            if (Capacitor.getPlatform() === 'android') {
                Intercom.receivePush(notification.data);
            }
            AppFunctions.updatePushNotificationSystem();
        });
    }
}

async function registerNotifications() {
    const store = useStore();
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (Capacitor.getPlatform() === 'android') {
        const status = await Diagnostic.isRemoteNotificationsEnabled();
        if (!status) {
            if (store.DisableNotifications == 'no') {
                Intercom.displayCarousel({ carouselId: '33713489' }); //android only
            }
        }
    }

    if (permStatus.receive !== 'granted' && Capacitor.getPlatform() != 'android') {
        if (store.DisableNotifications == 'no') {
            Intercom.displayCarousel({ carouselId: '33711553' }); //ios only
        }
    }
    await PushNotifications.register();
}

async function getNotificaitons() {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    AppFunctions.updatePushNotificationSystem();
}

async function addPushNotificationTapOpen() {
    const store = useStore();
    if (Capacitor.getPlatform() === 'web') {
        return;
    }

    //tap on notification to open app
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        if (notification) {
            if (notification.notification.data.message_id) {
                const conversationId = notification.notification.data?.conversation_id;
                if (conversationId) {
                    AppFunctions.openChat(conversationId, notification.notification.data.message_id);
                } else {
                    await AppFunctions.updatePushNotificationSystem();
                    const notificationList = store.PushNotificationList;
                    for (let i = 0; i < notificationList.length; i++) {
                        if (notificationList[i].id == notification.notification.data.message_id) {
                            await ModalController.notificationModal(notificationList[i]);
                            i = notificationList.length + 1;
                            return;
                        }
                    }
                }
            }
        }
    });
}

export { setupFCM, getNotificaitons, addPushNotificationTapOpen };

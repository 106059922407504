<template>
    <ion-card>
        <ion-card-header :style="headerStyle">
            <ion-card-title style="font-size: 22px; text-align: left">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-label style="display: flex; justify-content: center; font-weight: 700" v-if="list.length <= 0">{{
                noDataLabel
            }}</ion-label>
            <div v-if="list.length > 0">
                <slot />

                <ion-label
                    v-if="list.length >= 8 || listSize >= 8"
                    style="display: flex; justify-content: center; padding-top: 8px"
                    >{{ offset + 1 }} to {{ list.length + offset }} of {{ totalCount }}</ion-label
                >
                <div
                    v-if="list.length >= 8 || listSize >= 8"
                    style="display: flex; justify-content: space-between; width: 100%; white-space: nowrap"
                >
                    <div>
                        <ion-button @click="start()" color="primary" class="widget-content-list-button" size="default"
                            ><img src="./../../../assets/icons/skip-left-all.png"
                        /></ion-button>
                        <ion-button @click="back()" color="primary" class="widget-content-list-button" size="default"
                            ><img src="./../../../assets/icons/skip-left.png"
                        /></ion-button>
                    </div>
                    <div>
                        <ion-button @click="next()" color="primary" class="widget-content-list-button" size="default"
                            ><img src="./../../../assets/icons/skip-right.png"
                        /></ion-button>
                        <ion-button @click="end()" color="primary" class="widget-content-list-button" size="default"
                            ><img src="./../../../assets/icons/skip-right-all.png"
                        /></ion-button>
                    </div>
                </div>
            </div>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { computed, defineComponent, ref } from 'vue';
    import { mailOutline, ellipsisVerticalOutline, chatbubblesOutline } from 'ionicons/icons';

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            headerColor: {
                type: String,
                required: false,
                default: 'white',
            },
            list: {
                type: Array,
                required: false,
                default: () => {
                    return [];
                },
            },
            totalCount: {
                type: Number,
                required: false,
                default: 0,
            },
            noDataLabel: {
                type: String,
                required: false,
                default: 'No data available',
            },
        },
        emits: ['start', 'end', 'back', 'next'],
        setup(props, ctx) {
            const offset = ref(0);
            const listSize = ref(8);

            const headerStyle = computed(() => {
                const style = `background-image: linear-gradient(to bottom, ${props.headerColor}, white)`;
                return style;
            });

            const back = () => {
                if (offset.value <= 0) {
                    return;
                } else {
                    if (offset.value - listSize.value < 0) {
                        offset.value = 0;
                        ctx.emit('back');
                    } else {
                        offset.value = offset.value - listSize.value;
                        ctx.emit('back');
                    }
                }
            };

            const next = () => {
                if (offset.value + listSize.value >= props.totalCount) {
                    return;
                } else {
                    offset.value = offset.value + listSize.value;
                    ctx.emit('next');
                }
            };

            const start = () => {
                offset.value = 0;
                ctx.emit('start');
            };

            const end = () => {
                if (props.totalCount < listSize.value) {
                    return;
                }
                offset.value = props.totalCount - listSize.value;
                ctx.emit('end');
            };

            return {
                mailOutline,
                ellipsisVerticalOutline,
                chatbubblesOutline,
                offset,
                listSize,
                back,
                next,
                start,
                end,
                headerStyle,
            };
        },
    });
</script>

<style scoped>
    .widget-content-list-button {
        max-width: 50px;
        max-height: 50px;
        margin: 0 5px;
    }
</style>

<template>
    <ion-card style="margin-bottom: 10px; margin-top: 10px">
        <ion-card-content
            style="padding: 5px; background-image: linear-gradient(to right, rgb(240, 148, 98), rgb(226, 179, 152))"
        >
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-label style="font-size: 20px; font-weight: 800; color: white; display: flex">{{
                            store.departureAmount
                        }}</ion-label>
                        <ion-row>
                            <ion-label style="font-size: 20px; font-weight: 800; color: white">Departures</ion-label>
                        </ion-row>
                    </ion-col>
                    <div style="width: 65px; height: 50px">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fa"
                            data-icon="sign-out-alt"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="svg-inline--fa fa-sign-out-alt fa-w-16 fa-3x"
                            style="color: white"
                        >
                            <path
                                fill="currentColor"
                                d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                                class=""
                            ></path>
                        </svg>
                    </div>
                </ion-row>
            </ion-grid>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { useStore } from '@/store/store';
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            return {
                store,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        box-shadow: none;
    }
</style>

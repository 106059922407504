<template>
    <base-layout page-title="File Manager">
        <div style="max-width: var(--max-width); margin: auto">
            <ion-item style="cursor: pointer" @click="back()" v-if="currentDirectory != '/LODGIX'" lines="full">
                <ion-label slot="start">&lt; Back</ion-label>
                <ion-label hidden slot="end">{{ currentDirectory }}</ion-label>
            </ion-item>
            <ion-item v-if="currentDirectory === '/LODGIX'" lines="full">
                <ion-label hidden slot="start">ROOT</ion-label>
                <ion-label hidden slot="end">/LODGIX</ion-label>
            </ion-item>
            <template v-for="(item, index) in fileList" :key="index">
                <ion-item lines="full" v-if="item.name != '' && !item.name.includes('.')" style="cursor: pointer">
                    <ion-label @click="open(item)">{{ item.name }}</ion-label>
                    <ion-label @click="open(item)" slot="end" v-if="!item.name.includes('.')">></ion-label>
                    <ion-label slot="end" @click="deleteCustom(item)"><ion-icon :src="trash"></ion-icon></ion-label>
                </ion-item>
                <ion-item lines="full" v-if="item.name != '' && item.name.includes('.')">
                    <ion-label @click="open(item)">{{ item.name }}</ion-label>
                    <ion-label @click="open(item)" slot="end" v-if="!item.name.includes('.')">></ion-label>
                    <ion-label style="cursor: pointer" slot="end" @click="deleteCustom(item)"
                        ><ion-icon :src="trash"></ion-icon
                    ></ion-label>
                </ion-item>
            </template>
        </div>
    </base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { phonePortrait, trash } from 'ionicons/icons';
    import { deleteDirectory, deleteFile, readDirectory } from '@/services/LDXFilesystem';

    export default defineComponent({
        name: 'HomePage',
        components: {},
        setup() {
            const ionRouter = useIonRouter();
            return {
                ionRouter,
                trash,
            };
        },

        methods: {
            loadDirectory() {
                readDirectory(this.currentDirectory).then((value) => {
                    //console.log(value)
                    this.fileList = value;
                });
            },
            open(item: any) {
                if (!item.name.includes('.')) {
                    //folder
                    this.currentDirectory = this.currentDirectory + '/' + item.name;
                    this.loadDirectory();
                }
            },
            back() {
                const tmpPath = this.currentDirectory.split('/');
                let resultPath = '';
                for (let i = 1; i < tmpPath.length - 1; i++) {
                    resultPath = resultPath + '/' + tmpPath[i];
                }
                this.currentDirectory = resultPath;
                this.loadDirectory();
            },
            deleteCustom(item: any) {
                if (!item.name.includes('.')) {
                    //directory
                    deleteDirectory(this.currentDirectory + '/' + item.name).then((value) => {
                        this.loadDirectory();
                    });
                } else {
                    //file
                    deleteFile(this.currentDirectory + '/' + item.name).then((value) => {
                        this.loadDirectory();
                    });
                }
            },
        },

        mounted() {
            this.loadDirectory();
        },

        watch: {
            //
        },

        data() {
            return {
                fileList: [
                    {
                        name: '',
                        data: '',
                    },
                ],
                currentDirectory: '/LODGIX',
            };
        },
    });
</script>

<style scoped></style>

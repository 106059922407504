<template>
    <ion-page />
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted } from 'vue';
    import LodgixOAuth2 from '@/services/OAuth2';

    export default defineComponent({
        components: {},
        setup() {
            const ionRouter = useIonRouter();

            onMounted(async () => {
                await LodgixOAuth2.logout(false);
                await LodgixOAuth2.authenticateWithLodgix();
                ionRouter.replace('/home');
            });

            return {
                ionRouter,
            };
        },
    });
</script>

<template>
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.75 32.5C25.6201 32.5 32 26.1201 32 18.25C32 10.3799 25.6201 4 17.75 4C9.87994 4 3.5 10.3799 3.5 18.25C3.5 26.1201 9.87994 32.5 17.75 32.5Z"
            stroke="#747474"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="12.1506"
            y="10.639"
            width="18.3238"
            height="2.84502"
            rx="1.42251"
            transform="rotate(45 12.1506 10.639)"
            fill="#747474"
        />
        <rect
            x="10.139"
            y="23.5958"
            width="18.3238"
            height="2.84502"
            rx="1.42251"
            transform="rotate(-45 10.139 23.5958)"
            fill="#747474"
        />
    </svg>
</template>

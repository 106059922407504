<!-- eslint-disable vue/no-mutating-props -->
<template>
    <modal-base-layout title="Warning - Rules" background="rgb(239, 246, 255)">
        <ion-card>
            <ion-card-content>
                <p>The reservation violates some rules:</p>
                <br />
                <div v-for="item in errors" :key="item.name">
                    <p>
                        <b>{{ item.name }}</b>
                    </p>
                    <ul>
                        <li v-for="error in item.errors" :key="error.id">
                            {{ error }}
                        </li>
                    </ul>
                </div>
                <br />
                <p>Please press "Proceed Anyway" if this is correct.</p>
            </ion-card-content>
        </ion-card>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="cancel()">Cancel</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" size="medium" @click="confirm()"
                            >Proceed Anyway</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { arrowBackOutline } from 'ionicons/icons';
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {},
        props: ['errors'],
        setup() {
            const cancel = () => {
                return modalController.dismiss(false);
            };

            const confirm = () => {
                return modalController.dismiss(true);
            };

            return {
                arrowBackOutline,
                cancel,
                confirm,
            };
        },
    });
</script>

<style scoped></style>

import { useStore } from '@/store/store';
import { format } from 'date-fns';
import AppAPI from './AppAPI';

async function getWidgetData() {
    const store = useStore();
    if (store.id == '') {
        return;
    }

    let tmpValue = await AppAPI.getReservationPending();
    store.changePendingAmount(tmpValue.data);
    await getPendingContent(0);
    tmpValue = await AppAPI.getSummaryWidgetData();
    store.changeArrivalAmount(tmpValue.data.arrivals);
    store.changeDepartureAmount(tmpValue.data.departures);
    //occupancy amount
    let tmpOccupancy = tmpValue.data.occupancy;
    tmpOccupancy = tmpOccupancy * 100;
    tmpOccupancy = tmpOccupancy.toFixed(1);
    store.changeOccupancyAmount(tmpOccupancy);

    await getArrivalContent(0);
    await getDepartureContent(0);
    await getInquiriesWidgetContent(0);
}

async function getPendingContent(offset: number) {
    const store = useStore();
    const data = await AppAPI.getPendingWidgetContent(offset.toString());
    store.changePendingDataCount(data.data.total_records);
    store.changePendingDataArray(data.data.data);
}

async function getArrivalContent(offset: number) {
    const store = useStore();
    const data = await AppAPI.getArrivalsWidgetContent(offset.toString());
    store.changeArrivalDataCount(data.data.total_records);
    store.changeArrivalDataArray(data.data.data);
}

async function getDepartureContent(offset: number) {
    const store = useStore();
    const data = await AppAPI.getDeparturesWidgetContent(offset.toString());
    store.changeDepartureDataCount(data.data.total_records);
    store.changeDepartureDataArray(data.data.data);
}

async function getInquiriesWidgetContent(offset: number) {
    const store = useStore();
    const data = await AppAPI.getInquiriesWidgetContent(offset.toString());
    store.changeInquiryDataCount(data.data.total_records);
    store.changeInquiryDataArray(data.data.data);
}

export { getWidgetData, getPendingContent, getDepartureContent, getArrivalContent };

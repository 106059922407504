<template>
    <ion-page>
        <ion-header v-if="signedIn() && !hideHeader" class="ion-no-border" id="ion-header">
            <ion-toolbar color="primary" mode="md" id="ion-toolbar">
                <ion-buttons style="cursor: pointer" slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title style="font-family: 'Montserrat', sans-serif; font-weight: 700; text-align: center">
                    {{ pageTitle }}</ion-title
                >
                <ion-thumbnail style="cursor: pointer" slot="end" @click="goHome()">
                    <img src="@/assets/img/lodgix-icon-white.svg" />
                </ion-thumbnail>
            </ion-toolbar>
        </ion-header>

        <ion-header v-if="$slots.header" style="box-shadow: 0px 1px 5px 0px rgba(194, 194, 194, 1)">
            <ion-toolbar style="--background: white; padding: 0px !important">
                <slot name="header" />
            </ion-toolbar>
        </ion-header>

        <ion-content :mode="removeMode ? '' : 'ios'" :fullscreen="true" :style="[`--background: ${backgroundColor}`]">
            <slot />
        </ion-content>
        <ion-footer v-if="$slots.footer">
            <ion-toolbar style="--background: white; color: black">
                <slot name="footer" />
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<script lang="ts">
    import CommonFunctions from '@/services/AppFunctions';
    import { useStore } from '@/store/store';
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, watch, computed } from 'vue';

    export default defineComponent({
        props: {
            pageTitle: {
                type: String,
                required: true,
            },
            whiteBackground: {
                type: Boolean,
                required: false,
                default: false,
            },
            removeMode: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideHeader: {
                type: Boolean,
                required: false,
                default: false,
            },
            customBackgroundColor: {
                type: String,
                required: false,
                default: '',
            },
        },
        components: {},
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            const backgroundColor = computed(() => {
                if (props.whiteBackground) {
                    return 'rgb(239, 246, 255)';
                } else if (props.customBackgroundColor) {
                    return props.customBackgroundColor;
                }
                return '';
            });

            const signedIn = () => {
                const a = CommonFunctions.isSignedIn();
                if (!a) {
                    ionRouter.replace('/home');
                }
                return a;
            };

            const goHome = () => {
                store.changeActivePage('/home');
                if (props.pageTitle == 'Calendar Tape') {
                    window.location.href = '/home';
                } else {
                    ionRouter.replace('/home');
                }
            };

            return {
                ionRouter,
                backgroundColor,
                signedIn,
                goHome,
            };
        },
    });
</script>

import { loadingController } from '@ionic/vue';

const showLoading = (message: string): Promise<HTMLIonLoadingElement> => {
    return loadingController
        .create({
            message: message,
            mode: 'ios',
        })
        .then((loading) => {
            loading.present();
            return loading;
        });
};

const hideLoading = (loading: HTMLIonLoadingElement): void => {
    loadingController.dismiss(loading);
};

export { showLoading, hideLoading };

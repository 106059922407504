import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"padding-bottom":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_vc_date_picker = _resolveComponent("vc-date-picker")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, {
          lines: "none",
          class: "ion-no-padding"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, { style: {"font-size":"20px"} }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.TitleLabel), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      (!_ctx.isReset)
        ? (_openBlock(), _createBlock(_component_vc_date_picker, {
            key: 0,
            class: "custom-calendar",
            style: {"width":"100%"},
            "is-range": "",
            "min-date": new Date(),
            modelValue: _ctx.dateRange,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event))
          }, null, 8, ["min-date", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.store.reservationEndDate != '')
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 0,
          color: "danger",
          style: {"--border-radius":"10px","width":"100%","padding-top":"10px"},
          onClick: _ctx.clearDates
        }, {
          default: _withCtx(() => [
            _createTextVNode("Clear Dates")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}
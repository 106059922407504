function getDataURL(file: any) {
    return new Promise((resolve, reject) => {
        const fReader = new FileReader();

        fReader.onload = () => {
            return resolve(fReader.result);
        };

        fReader.onerror = (event) => {
            return reject(event);
        };

        fReader.readAsDataURL(file);
    });
}

function getImageResolution(file: any) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const src = URL.createObjectURL(file);
        image.onload = () => {
            const data = {
                width: image.width,
                height: image.height,
            };
            URL.revokeObjectURL(src);
            return resolve(data);
        };

        image.onerror = (event) => {
            URL.revokeObjectURL(src);
            return reject(event);
        };

        image.src = src;
    });
}

export { getDataURL, getImageResolution };

<template>
    <modal-base-layout title="Notification Details">
        <template #header>
            <div style="display: flex; flex-direction: column; color: black; padding: 5px">
                <ion-label style="font-size: large; font-weight: 700">{{ item.subject }}</ion-label>
                <ion-label text-wrap>{{ formatDate(item.date) }}</ion-label>
            </div>
        </template>

        <ion-item lines="none">
            <ion-label style="white-space: pre-wrap; overflow-wrap: break-word">
                {{ item.body }}
            </ion-label>
        </ion-item>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button
                            v-if="showChatButton"
                            class="modal-footer-button"
                            color="primary"
                            @click="chatButton"
                            >View In Unified Inbox</ion-button
                        >
                        <ion-button
                            v-if="showWorkOrderButton"
                            class="modal-footer-button"
                            color="primary"
                            @click="workOrderButton"
                            >View Work Order</ion-button
                        >
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="cancel()">Back</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="confirm()"
                            >Mark As Read</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { arrowBackOutline } from 'ionicons/icons';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { format } from 'date-fns';
    import ModalController from '@/services/ModalController';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {},
        props: ['item'],
        setup(props) {
            const invoiceNumber = ref('');
            const store = useStore();

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = () => {
                return modalController.dismiss({}, 'confirm');
            };

            const formatDate = (date: string) => {
                return format(new Date(date), 'MMMM dd, yyyy - hh:mm a');
            };

            const openControlPanelModal = async () => {
                await ModalController.controlPanelModal(invoiceNumber.value);
            };

            const chatButton = () => {
                let conversationId = props.item.href.split('conversation/')[1];
                if (conversationId) {
                    conversationId = conversationId.split('/')[0];
                    AppFunctions.openChat(conversationId);
                }
            };

            const workOrderButton = () => {
                let workOrderId = props.item.href.split('work_order_id=')[1];
                if (workOrderId) {
                    window.location.href = '/workOrders/' + '?id=' + workOrderId;
                    cancel();
                }
            };

            const showWorkOrderButton = computed(() => {
                return props.item.href?.includes('work_order_id=');
            });

            const showChatButton = computed(() => {
                return props.item.href?.includes('conversation');
            });

            return {
                arrowBackOutline,
                invoiceNumber,
                store,
                showChatButton,
                showWorkOrderButton,
                workOrderButton,
                chatButton,
                cancel,
                confirm,
                formatDate,
                openControlPanelModal,
            };
        },
    });
</script>

<style scoped>
    ion-item {
        --background: transparent;
    }
</style>

<template>
    <ion-menu mode="ios" side="start" content-id="content" v-if="accessToken">
        <ion-header class="ion-no-border" style="background-color: #f0f2f5">
            <ion-toolbar color="#F0F2F5">
                <ion-item lines="none" class="ldx-nav-header">
                    <ion-avatar slot="start">
                        <img
                            v-if="store.companyLogo != '' && store.companyLogo != null"
                            class="ldx-menu-logo"
                            alt="Logo"
                            :src="store.companyLogo"
                        />
                        <img
                            v-if="store.companyLogo == ''"
                            class="ldx-menu-logo"
                            alt="Logo"
                            src="@/assets/img/lodgix-icon-color-2.svg"
                        />
                    </ion-avatar>
                    <ion-label>
                        <ion-list-header
                            v-if="store.companyName != ''"
                            style="padding: 0px; font-size: clamp(0.8rem, 4.25vw - 1.5rem, 0.9rem)"
                            >{{ store.companyName }}</ion-list-header
                        >
                        <ion-card-subtitle
                            v-if="store.companyEmail != ''"
                            style="font-size: clamp(0.5rem, 4.25vw - 1.5rem, 0.65rem); font-weight: bold"
                            >{{ store.companyEmail }}</ion-card-subtitle
                        >
                        <ion-list-header
                            v-if="store.companyName == ''"
                            style="padding: 0px; font-size: clamp(0.8rem, 4.25vw - 1.5rem, 0.9rem)"
                            >{{ store.userFirstName }} {{ store.userLastName }}</ion-list-header
                        >
                        <ion-card-subtitle
                            v-if="store.companyEmail == ''"
                            style="font-size: clamp(0.5rem, 4.25vw - 1.5rem, 0.65rem); font-weight: bold"
                            >{{ store.userEmail }}</ion-card-subtitle
                        >
                    </ion-label>
                </ion-item>
            </ion-toolbar>
        </ion-header>
        <ion-searchbar :debounce="0" @ionInput="searchChange($event)"></ion-searchbar>

        <ion-content>
            <div v-if="store.debug" style="display: flex; flex-direction: column">
                <ion-label>{{ accessToken }}</ion-label>
                <br />
                <ion-button @click="changeAccessToken()">
                    <ion-icon slot="icon-only" :icon="refreshOutline"></ion-icon>
                </ion-button>
            </div>
            <ion-list style="padding: 0px; margin: 0px">
                <ion-menu-toggle
                    :auto-hide="false"
                    v-for="(page, index) in sideMenu"
                    :key="index"
                    style="cursor: pointer"
                >
                    <div
                        :style="[
                            activePage.toLowerCase() === page.url.toLowerCase()
                                ? 'background: rgba(var(--ion-color-primary-rgb), 0.14) !important'
                                : '',
                        ]"
                    >
                        <ion-item
                            v-if="
                                ((page.keyWords.toLocaleLowerCase().includes(searchValue) && searchValue != '') ||
                                    searchValue == '') &&
                                !(page.url == '/support' && getPlatform() === 'web') &&
                                checkScope(page.scopes) &&
                                checkRoles(page.roles)
                            "
                            lines="none"
                            @click="navigatePage(page.url)"
                        >
                            <ion-icon
                                slot="start"
                                :ios="page.iosIcon"
                                :md="page.mdIcon"
                                :color="activePage.toLowerCase() === page.url.toLowerCase() ? 'primary' : 'medium'"
                            ></ion-icon>
                            <ion-label
                                :color="activePage.toLowerCase() === page.url.toLowerCase() ? 'primary' : 'dark'"
                                >{{ page.title }}</ion-label
                            >
                            <ion-badge
                                v-if="
                                    page.title === 'Notifications' &&
                                    store.pushNotificationUnreadCount > 0 &&
                                    store.pushNotificationUnreadCount < 100
                                "
                                color="danger"
                                >{{ store.pushNotificationUnreadCount }}</ion-badge
                            >
                            <ion-badge
                                v-if="page.title === 'Notifications' && store.pushNotificationUnreadCount >= 100"
                                color="danger"
                                >99+</ion-badge
                            >
                        </ion-item>
                    </div>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, ref, watch } from 'vue';
    import {
        calendarClear,
        calendarNumber,
        home,
        list,
        calendar,
        personCircle,
        chatbubbles,
        people,
        refreshOutline,
    } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import LodgixOAuth2 from '@/services/OAuth2';
    import AppFunctions from '@/services/AppFunctions';
    import { Capacitor } from '@capacitor/core';
    import { checkScope, checkRoles, calendarAccess } from '@/types/accessGroups';
    import { accessToken, updateAuthTokens, refreshToken } from '@/services/OAuth2';
    import { sideMenu } from '@/utils/constants';

    export default defineComponent({
        name: 'SideMenu',
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();

            const searchValue = ref('');
            const activePage = ref(store.ActivePage);

            watch(
                () => accessToken.value,
                async (val) => {
                    if (accessToken.value == '' && refreshToken.value != '') {
                        await LodgixOAuth2.logout();
                    }
                }
            );

            watch(
                () => store.ActivePage,
                (val) => {
                    activePage.value = val;
                }
            );

            const getPlatform = () => {
                return Capacitor.getPlatform();
            };

            const searchChange = (e: any) => {
                searchValue.value = e.target.value.toLowerCase();
            };

            const changeAccessToken = async () => {
                await updateAuthTokens('fiw83h938hr8w3rh', refreshToken.value);
            };

            const navigatePage = async (url: string) => {
                await AppFunctions.transitionClear(url);
            };

            return {
                ionRouter,
                home,
                calendarNumber,
                calendarClear,
                list,
                calendar,
                sideMenu,
                personCircle,
                chatbubbles,
                people,
                searchValue,
                activePage,
                store,
                accessToken,
                AppFunctions,
                refreshOutline,
                refreshToken,
                changeAccessToken,
                checkRoles,
                getPlatform,
                searchChange,
                checkScope,
                navigatePage,
            };
        },
    });
</script>

<style scoped>
    .ldx-menu-list {
        padding-top: 0;
    }

    .ldx-nav-header {
        --background: #f0f2f5;
    }

    .ldx-nav-header ion-avatar,
    .ldx-nav-header img {
        --background: #ffffff;
    }

    .ldx-nav-header ion-list-header {
        padding-left: 0;
        padding: 0px;
        line-height: 1;
        font-size: 16px;
        min-height: 20px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }

    .ldx-nav-header ion-card-subtitle {
        font-size: 14px;
    }

    ion-menu ion-content {
        --background: var(--ion-item-background, var(--ion-background-color, #fff));
    }

    ion-menu.md ion-list {
        padding: 10px 8px;
    }

    ion-menu.md ion-note {
        margin-bottom: 30px;
    }

    ion-menu.md ion-list#inbox-list {
        border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
    }

    ion-menu.md ion-list#inbox-list ion-list-header {
        font-size: 22px;
        font-weight: 600;
        min-height: 20px;
    }

    ion-menu.md ion-list#labels-list ion-list-header {
        font-size: 16px;
        margin-bottom: 18px;
        color: #757575;
        min-height: 26px;
    }

    ion-menu.md ion-item {
        --padding-start: 10px;
        --padding-end: 10px;
        border-radius: 4px;
    }

    ion-menu.md ion-item.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);
    }

    ion-menu.md ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-menu.md ion-item ion-icon {
        color: #616e7e;
    }

    ion-menu.md ion-item ion-label {
        font-weight: 500;
    }

    ion-menu.ios ion-content {
        --padding-bottom: 20px;
    }

    ion-menu.ios ion-list {
        padding: 20px 0 0 0;
    }

    ion-menu.ios ion-note {
        line-height: 24px;
        margin-bottom: 20px;
    }

    ion-menu.ios ion-item {
        --padding-start: 16px;
        --padding-end: 16px;
        --min-height: 50px;
    }

    ion-menu.ios ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-menu.ios ion-item ion-icon {
        font-size: 24px;
        color: #73849a;
    }

    ion-menu.ios ion-list#labels-list ion-list-header {
        margin-bottom: 8px;
    }

    ion-menu.ios ion-list-header,
    ion-menu.ios ion-note {
        padding-left: 16px;
        padding-right: 16px;
    }

    ion-menu.ios ion-note {
        margin-bottom: 8px;
    }

    ion-note {
        display: inline-block;
        font-size: 16px;

        color: var(--ion-color-medium-shade);
    }

    ion-avatar {
        --border-radius: 4px;
        background: #fff;
    }
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-97abb360"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"display":"flex","align-items":"center"},
  class: "ion-nowrap"
}
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column","align-items":"left","width":"100%"} }
const _hoisted_3 = { style: {"display":"flex","align-items":"center","justify-content":"space-between","width":"100%"} }
const _hoisted_4 = {
  key: 0,
  style: {"font-size":"10px","color":"#a9a9a9","font-weight":"normal"}
}
const _hoisted_5 = { style: {"font-weight":"bolder"} }
const _hoisted_6 = { style: {"font-weight":"bolder"} }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 0,
  style: {"display":"flex","justify-content":"space-between","width":"100%","margin-top":"5px","margin-bottom":"5px"}
}
const _hoisted_9 = { style: {"display":"flex","margin-left":"-5px"} }
const _hoisted_10 = { style: {"width":"100%","display":"flex","justify-content":"center","padding":"10px","padding-bottom":"20px"} }
const _hoisted_11 = { style: {"display":"flex","justify-content":"center","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_DamageNoticeIcon = _resolveComponent("DamageNoticeIcon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    "page-title": "Work Orders",
    whiteBackground: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.store.workOrderPermissions.can_add)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              class: "modal-header-button",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createWorkOrder()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { src: _ctx.add }, null, 8, ["src"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_button, { class: "select-button" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_select, {
              modelValue: _ctx.showClosed,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showClosed) = $event)),
              placeholder: "Show Closed"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select_option, { value: false }, {
                  default: _withCtx(() => [
                    _createTextVNode("Hide Closed")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_select_option, { value: true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Show Closed")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ion_label, { style: {"font-size":"larger"} }, {
          default: _withCtx(() => [
            _createTextVNode("Total: " + _toDisplayString(_ctx.store.WorkOrderListTotal) + " Work Orders", 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.WorkOrderList, (item, index) => {
          return (_openBlock(), _createBlock(_component_ion_card, {
            key: index,
            onClick: ($event: any) => (_ctx.openModal(item.id.toString())),
            style: {"cursor":"pointer"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_content, {
                class: "ion-no-padding",
                style: {"padding":"10px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([
                                            !item.is_viewed ? 'font-weight:bolder;' : 'font-weight:normal;',
                                            'font-size:18px;',
                                        ])
                        }, _toDisplayString(item.number) + ": " + _toDisplayString(item.property_name), 5),
                        (item.date_reported)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createTextVNode(" Reported by "),
                              _createElementVNode("span", _hoisted_5, _toDisplayString(item.reporter_name), 1),
                              _createTextVNode(" on "),
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatDate(item.date_reported)), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      (item.assigned_to !== '-')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            style: {"border-radius":"50%","width":"30px","height":"30px"},
                            src: _ctx.generateAvatarFromName(item.assigned_to),
                            alt: item.assigned_to
                          }, null, 8, _hoisted_7))
                        : _createCommentVNode("", true)
                    ]),
                    (item.description)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", {
                            style: _normalizeStyle([{"display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis","font-size":"12px"}, [item.description ? '' : 'color:#a9a9a9']])
                          }, _toDisplayString(item.description ? item.description : 'No Description'), 5)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ion_chip, { style: {"background":"#f5f5f5","color":"#000","margin":"5px"} }, {
                      default: _withCtx(() => [
                        (_ctx.statusIcon(item.status_name.toUpperCase()).icon)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.statusIcon(item.status_name.toUpperCase()).icon,
                              style: _normalizeStyle({ color: _ctx.statusIcon(item.status_name.toUpperCase()).color })
                            }, null, 8, ["icon", "style"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_label, { style: {"font-size":"10px"} }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.status_name.toUpperCase()), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_chip, { style: {"background":"#f5f5f5","color":"#000","margin":"5px"} }, {
                      default: _withCtx(() => [
                        (_ctx.priorityIcon(item.priority_name.toUpperCase()).icon)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.priorityIcon(item.priority_name.toUpperCase()).icon,
                              style: _normalizeStyle({ color: _ctx.priorityIcon(item.priority_name.toUpperCase()).color })
                            }, null, 8, ["icon", "style"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_label, { style: {"font-size":"10px"} }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.priority_name.toUpperCase()), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    (item.damage_notice_source_id)
                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                          key: 0,
                          style: {"background":"#f5f5f5","color":"#000","margin":"5px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DamageNoticeIcon, { style: {"fill":"#0d47a1","width":"20px","height":"20px"} })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128)),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.store.WorkOrderList.length < _ctx.store.WorkOrderListTotal)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                class: "filter-button",
                color: "primary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadMore()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("View More")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
<template>
    <ion-page>
        <ion-content class="ldx-login-content" :fullscreen="true">
            <div style="padding-bottom: 100px; max-width: var(--max-width); margin: auto">
                <ion-card style="margin: 15px">
                    <ion-card-content>
                        <ion-card-title style="font-size: 30px; text-align: center">Welcome to Lodgix</ion-card-title>
                        <div class="ion-text-center" style="padding-top: 10px">
                            <ion-label style="font-size: 20px; font-weight: 700">{{ dynamicWelcomeMessage }}</ion-label>
                            <br />
                            <ion-label style="font-size: 20px; font-weight: 700">{{ date }}</ion-label>
                        </div>
                    </ion-card-content>
                </ion-card>

                <ion-card style="margin: 15px; margin-bottom: 0px">
                    <ion-card-content>
                        <ion-list lines="full" style="width: 100%">
                            <template v-for="(item, index) in mainMenu" :key="index">
                                <ion-item
                                    v-if="checkScope(item.scopes) && checkRoles(item.roles)"
                                    style="cursor: pointer"
                                    @click="AppFunctions.transitionClear(item.url)"
                                    :lines="index === mainMenu.length - 1 ? 'none' : 'full'"
                                    :detail="true"
                                    ><ion-icon
                                        style="padding-bottom: 5px; padding-top: 5px"
                                        :icon="item.icon"
                                        :color="item.color"
                                        slot="start"
                                    ></ion-icon>
                                    <ion-label style="font-size: larger; padding-bottom: 5px; padding-top: 5px">
                                        <strong>{{ item.label }}</strong>
                                    </ion-label>
                                    <template v-if="item.url === '/notifications'">
                                        <ion-badge
                                            v-if="
                                                store.pushNotificationUnreadCount > 0 &&
                                                store.pushNotificationUnreadCount < 100
                                            "
                                            color="danger"
                                            >{{ store.pushNotificationUnreadCount }}</ion-badge
                                        >
                                        <ion-badge v-if="store.pushNotificationUnreadCount >= 100" color="danger"
                                            >99+</ion-badge
                                        >
                                    </template>
                                </ion-item>
                            </template>
                        </ion-list>
                    </ion-card-content>
                </ion-card>

                <div style="padding-top: 15px" v-if="widgetAccess()">
                    <WidgetComponent />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import AppFunctions from '@/services/AppFunctions';
    import { useStore } from '@/store/store';
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent } from '@vue/runtime-core';
    import { format } from 'date-fns';
    import {
        listOutline,
        alertCircle,
        lockClosed,
        calendarNumberOutline,
        notificationsOutline,
        chatbubblesOutline,
        calendarOutline,
        calendarClearOutline,
    } from 'ionicons/icons';
    import { refreshToken } from '@/services/OAuth2';
    import { accessToken } from '@/services/OAuth2';
    import WidgetComponent from '@/components/widgets/WidgetComponent.vue';
    import { mainMenu } from '@/utils/constants';
    import { widgetAccess, checkScope, checkRoles } from '@/types/accessGroups';
    import { computed } from 'vue';

    export default defineComponent({
        components: {
            WidgetComponent,
        },

        setup() {
            const store = useStore();
            var date = format(new Date(), 'MM/dd/yyyy');
            const ionRouter = useIonRouter();

            const dynamicWelcomeMessage = computed(() => {
                const currentHour = new Date().getHours();
                let greeting = 'Hello';

                if (currentHour < 12) {
                    greeting = 'Good morning,';
                } else if (currentHour < 18) {
                    greeting = 'Good afternoon,';
                } else if (currentHour < 24) {
                    greeting = 'Good evening,';
                }

                return `${greeting} ${store.UserFirstName}`;
            });

            return {
                date,
                ionRouter,
                listOutline,
                alertCircle,
                lockClosed,
                calendarNumberOutline,
                notificationsOutline,
                chatbubblesOutline,
                calendarOutline,
                calendarClearOutline,
                store,
                accessToken,
                refreshToken,
                AppFunctions,
                mainMenu,
                dynamicWelcomeMessage,
                checkScope,
                checkRoles,
                widgetAccess,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        margin: 10px;
    }
</style>

export const languagesList = {
    en: {
        PLEASE_SIGN_IN: 'Please sign in and authorize access to your Lodgix account.',
        SIGN_IN: 'SIGN IN',
        SIGN_OUT: 'Sign Out',
        CLEAN_IMMEDIATELY: 'Clean Immediately',
        TODAY: 'Today',
        NEXT_7_DAYS: 'Next Seven Days',
        FUTURE_JOBS: 'Jobs In The Future',
        NOTHING: 'Nothing',
        INSTRUCTIONS: 'Instructions',
        COMMENTS: 'Comments',
        CURRENT_GUEST: 'Current Guest',
        NEXT_GUEST: 'Next Guest',
        NEXT_GUEST_UNKNOWN: 'Not known',
        DEPARTS: 'Out',
        ARRIVES: 'In',
        START: 'START CLEANING JOB',
        DONE: "I'M DONE",
        SAVE: 'Save',
        CANCEL: 'Cancel',
        COULD_NOT_LOAD_DATA: 'Could not load data. Check your Internet connection.',
        COULD_NOT_SAVE_COMMENTS: 'Could not save comments. Check your Internet connection.',
        COULD_NOT_SAVE_STATUS: 'Could not update job status. Check your Internet connection.',
        NO_JOBS_TO_DO: 'No Jobs To Do',
        JOB_DETAILS: 'Job Details',
        BACK: 'Back',
        HOME: 'Home',
        ACCOUNT: 'Account',
    },
    es: {
        PLEASE_SIGN_IN: 'Inicie sesión y autorice el acceso a su cuenta Lodgix.',
        SIGN_IN: 'REGISTRARSE',
        SIGN_OUT: 'Desconectar',
        CLEAN_IMMEDIATELY: 'Limpiar inmediatamente',
        TODAY: 'Hoy',
        NEXT_7_DAYS: 'Próximos siete días',
        FUTURE_JOBS: 'Empleos en el futuro',
        NOTHING: 'Nada',
        INSTRUCTIONS: 'Instrucciones',
        COMMENTS: 'Comentarios',
        CURRENT_GUEST: 'Invitado actual',
        NEXT_GUEST: 'Siguiente invitado',
        NEXT_GUEST_UNKNOWN: 'No conocida',
        DEPARTS: 'Se va',
        ARRIVES: 'Llega',
        START: 'COMIENCE EL TRABAJO DE LIMPIEZA',
        DONE: 'YA TERMINÉ',
        SAVE: 'Salvar',
        CANCEL: 'Cancelar',
        COULD_NOT_LOAD_DATA: 'No se pudieron cargar los datos. Comprueba tu conexión a Internet.',
        COULD_NOT_SAVE_COMMENTS: 'No se pudieron guardar los comentarios. Comprueba tu conexión a Internet.',
        COULD_NOT_SAVE_STATUS: 'No se pudo actualizar el estado del trabajo. Comprueba tu conexión a Internet.',
        NO_JOBS_TO_DO: 'Sin Trabajos Que Hacer',
        JOB_DETAILS: 'Detalles Del Trabajo',
        BACK: 'Atrás',
        HOME: 'Hogar',
        ACCOUNT: 'Cuenta',
    },
    pt: {
        PLEASE_SIGN_IN: 'Por favor entre e autorize acesso à sua conta Lodgix.',
        SIGN_IN: 'ENTRAR',
        SIGN_OUT: 'Sair',
        CLEAN_IMMEDIATELY: 'Limpe imediatamente',
        TODAY: 'Hoje',
        NEXT_7_DAYS: 'Próximos sete dias',
        FUTURE_JOBS: 'Tarefas futuras',
        NOTHING: 'Nada',
        INSTRUCTIONS: 'Instruções',
        COMMENTS: 'Notas',
        CURRENT_GUEST: 'Hóspede Actual',
        NEXT_GUEST: 'Próximo Hóspede',
        NEXT_GUEST_UNKNOWN: 'Não conhecido',
        DEPARTS: 'Partidas',
        ARRIVES: 'Chegadas',
        START: 'Começar tarefa de limpeza',
        DONE: 'Terminado',
        SAVE: 'Guardar',
        CANCEL: 'Cancelar',
        COULD_NOT_LOAD_DATA: 'Não foi possível carregar dados. Verifique a sua conexão à Internet.',
        COULD_NOT_SAVE_COMMENTS: 'Não foi possível guardar notas. Verifique a sua conexão à Internet.',
        COULD_NOT_SAVE_STATUS: 'Não foi possível actualizar estado da tarefa. Verifique a sua conexão à Internet.',
        NO_JOBS_TO_DO: 'Sem Trabalho Para Fazer',
        JOB_DETAILS: 'Detalhes Do Trabalho',
        BACK: 'Voltar',
        HOME: 'Lar',
        ACCOUNT: 'Conta',
    },
    ru: {
        PLEASE_SIGN_IN: 'Авторизируйте, пожалуйста, доступ к Вашему аккаунту Lodgix.',
        SIGN_IN: 'ВОЙТИ',
        SIGN_OUT: 'ВЫЙТИ',
        CLEAN_IMMEDIATELY: 'Срочно убрать',
        TODAY: 'Сегодня',
        NEXT_7_DAYS: 'Следующие 7 дней',
        FUTURE_JOBS: 'На будущее',
        NOTHING: 'Ничего',
        INSTRUCTIONS: 'Инструкции',
        COMMENTS: 'Комментарии',
        CURRENT_GUEST: 'Тек. гость',
        NEXT_GUEST: 'След. гость',
        NEXT_GUEST_UNKNOWN: 'Не известно',
        DEPARTS: 'Отъезд',
        ARRIVES: 'Приезд',
        START: 'НАЧАТЬ УБОРКУ',
        DONE: 'УБОРКА ОКОНЧЕНА',
        SAVE: 'Сохр.',
        CANCEL: 'Отмена',
        COULD_NOT_LOAD_DATA: 'Не могу загрузить данные. Проверьте подключение к интернету.',
        COULD_NOT_SAVE_COMMENTS: 'Не могу сохранить комментарии. Проверьте подключение к интернету.',
        COULD_NOT_SAVE_STATUS: 'Не могу сохранить состояние уборки. Проверьте подключение к интернету.',
        NO_JOBS_TO_DO: 'никакой работы',
        JOB_DETAILS: 'детали работы',
        BACK: 'назад',
        HOME: 'дом',
        ACCOUNT: 'счет',
    },
};

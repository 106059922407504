<template>
    <base-layout page-title="Test Page"> </base-layout>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {},
        setup() {
            return {
                //
            };
        },
    });
</script>

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_card_header, { class: "ldx-section-start" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode("Property Quote")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        (_ctx.store.reservationPropertyListQuotes.deposits != '')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 0,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  style: {"text-align":"left"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Deposits: ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  slot: "end",
                  style: {"text-align":"right"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.deposits)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationPropertyListQuotes.discount != '')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 1,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  style: {"text-align":"left"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Discounts: ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  slot: "end",
                  style: {"text-align":"right"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.discount)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationPropertyListQuotes.extra_guest_fee != '')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 2,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  style: {"text-align":"left"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Extra Guest Fee: ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  slot: "end",
                  style: {"text-align":"right"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.extra_guest_fee)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationPropertyListQuotes.fees != '')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 3,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  style: {"text-align":"left"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fees: ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  slot: "end",
                  style: {"text-align":"right"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.fees)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationPropertyListQuotes.rental_charges != '')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 4,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  style: {"text-align":"left"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Rental Charges: ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  slot: "end",
                  style: {"text-align":"right"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.rental_charges)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_item, {
          lines: "full",
          style: {"border-bottom":"2px solid black"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              class: "ion-text-wrap",
              style: {"text-align":"left"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Taxes: ")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, {
              class: "ion-text-wrap",
              slot: "end",
              style: {"text-align":"right"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.taxes)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              class: "ion-text-wrap",
              style: {"text-align":"left"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Total: ")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, {
              class: "ion-text-wrap",
              slot: "end",
              style: {"text-align":"right"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getDisplayMoney(_ctx.store.reservationPropertyListQuotes.total)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
<template>
    <base-layout page-title="Notifications" whiteBackground>
        <!--
            No Notification Card
        -->
        <div
            v-if="store.PushNotificationList.length <= 0"
            style="width: 100%; height: 100%; overflow: hidden; position: fixed"
            class="bell-label"
            ref="bellLabel"
        >
            <div style="width: 70%; height: 35%; margin: 15%; margin-bottom: 0px">
                <img style="width: 100%; height: 100%" src="@/assets/icons/notifications-icon.svg" />
            </div>
            <div style="display: flex; justify-content: center; padding: 0px; margin: 0px">
                <ion-label style="font-size: x-large; font-weight: 800">No Notifications</ion-label>
            </div>
        </div>

        <!--
            Notification List
        -->
        <div style="margin-top: 2px">
            <ion-list ref="notificationList" style="background: transparent; margin: auto" lines="none">
                <ion-item-sliding
                    v-for="(item, index) in store.pushNotificationList"
                    :key="index"
                    @ionSwipe="markAsRead($event, item.id.toString(), index)"
                >
                    <ion-item ref="ionItem" style="--background: transparent; margin-left: -23px; margin-right: -23px">
                        <div
                            style="
                                margin: 15px;
                                margin-bottom: 8px;
                                margin-top: 8px;
                                border-radius: 10px;
                                background: white;
                                border-style: solid;
                                border-width: 1px;
                                border-color: white;
                                -webkit-box-shadow: 0px 0px 11px 2px rgba(224, 224, 224, 1);
                                -moz-box-shadow: 0px 0px 11px 2px rgba(224, 224, 224, 1);
                                box-shadow: 0px 0px 11px 2px rgba(224, 224, 224, 1);
                                width: 100%;
                                cursor: pointer;
                            "
                            @click="openNotification(item)"
                        >
                            <ion-grid>
                                <ion-row>
                                    <ion-col style="align-items: center">
                                        <ion-label
                                            v-if="item.subject.length < 80"
                                            style="font-size: 18px; font-weight: 800; color: black; align-items: center"
                                            text-wrap
                                            >{{ item.subject }}</ion-label
                                        >
                                        <ion-label
                                            v-if="item.subject.length >= 80"
                                            style="font-size: 18px; font-weight: 800"
                                            text-wrap
                                            >{{ item.subject.slice(0, 80) }}...</ion-label
                                        >
                                        <ion-label style="font-size: small; font-weight: 500" text-wrap>{{
                                            formatDate(item.date)
                                        }}</ion-label>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col>
                                        <ion-label
                                            v-if="item.body.length < 110"
                                            style="font-size: medium; font-weight: 500"
                                            text-wrap
                                            >{{ item.body }}</ion-label
                                        >
                                        <ion-label
                                            v-if="item.body.length >= 110"
                                            style="font-size: medium; font-weight: 500"
                                            text-wrap
                                            >{{ item.body.slice(0, 110) }}...</ion-label
                                        >
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>
                    </ion-item>
                    <ion-item-options side="end">
                        <div ref="checkmark" class="checkmark">
                            <img style="width: 75px; height: 75px" src="@/assets/icons/checkmark-icon.svg" />
                        </div>
                        <ion-item-option
                            color="transpartent"
                            expandable="true"
                            side="end"
                            @click="markAsRead('', item.id.toString(), index)"
                        >
                            <ion-icon ref="icon" style="width: 50px; height: 50px" :icon="checkmarkOutline" />
                        </ion-item-option>
                    </ion-item-options>
                </ion-item-sliding>
            </ion-list>
        </div>

        <div style="padding-bottom: 100px"></div>

        <ion-fab slot="fixed" vertical="bottom" horizontal="end">
            <ion-fab-button>
                <ion-icon :icon="chevronUp"></ion-icon>
            </ion-fab-button>
            <ion-fab-list side="top">
                <ion-fab-button v-if="getPlatform() != 'web'" title="Help" @click="openSupport">
                    <ion-icon :icon="document"></ion-icon>
                </ion-fab-button>
                <ion-fab-button title="Mark All As Read" @click="markAllRead">
                    <ion-icon :icon="checkmarkOutline"></ion-icon>
                </ion-fab-button>
                <ion-fab-button title="Refresh" @click="handleRefresh">
                    <ion-icon :icon="reload"></ion-icon>
                </ion-fab-button>
                <ion-fab-button v-if="getPlatform() != 'web'" title="Device Settings" @click="openDeviceSettings">
                    <ion-icon :icon="settings"></ion-icon>
                </ion-fab-button>
                <ion-fab-button
                    v-if="getPlatform() != 'web'"
                    title="Push Notification Settings"
                    @click="openDeviceModal"
                >
                    <ion-icon :icon="notifications"></ion-icon>
                </ion-fab-button>
                <ion-fab-button
                    title="Information"
                    @click="
                        basicAlert(
                            'Information',
                            '',
                            'This page displays all unread notifications. To view read notifications and more, please visit the Lodgix.com website.'
                        )
                    "
                >
                    <ion-icon :icon="information"></ion-icon>
                </ion-fab-button>
            </ion-fab-list>
        </ion-fab>
    </base-layout>
</template>

<script lang="ts">
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import {
        notifications,
        chevronForwardOutline,
        alertCircleOutline,
        checkmarkCircleOutline,
        ellipse,
        checkmarkOutline,
        chevronUp,
        document,
        colorPalette,
        cog,
        reload,
        settings,
        information,
    } from 'ionicons/icons';
    import AppAPI from '@/services/AppAPI';
    import AppFunctions from '@/services/AppFunctions';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import ModalController from '@/services/ModalController';
    import { Capacitor } from '@capacitor/core';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { Intercom } from '@capacitor-community/intercom';
    import { advancedAlert, basicAlert } from '@/services/AlertController';
    import { errorToast } from '@/services/Toast';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const waitTimer = ref(500); //min of 300
            const showNotificationList = ref(false);
            const bellLabel = ref();
            const ionItem = ref();
            const icon = ref();
            const notificationList = ref();
            const checkmark = ref();

            const handleRefresh = async () => {
                const loadingService = await showLoading('Loading...');
                try {
                    await loadNotifications();
                    hideLoading(loadingService);
                } catch (error) {
                    hideLoading(loadingService);
                }
            };

            const openNotification = async (item: any) => {
                await ModalController.notificationModal(item);
                await loadNotifications();
            };

            const loadNotifications = async () => {
                await AppFunctions.updatePushNotificationSystem();
            };

            const formatDate = (date: string) => {
                if (format(new Date(date), 'MMMM dd, yyyy') == format(new Date(), 'MMMM dd, yyyy')) {
                    return format(new Date(date), 'h:mm a');
                }
                return format(new Date(date), 'MMM dd, yyyy');
            };

            const markAsRead = async (event: any, id: string, index: number) => {
                const item = ionItem;
                const icons = icon;
                if (item.value != null) {
                    item.value[index].$el.style.visibility = 'hidden';
                    if (notificationList.value != null) {
                        notificationList.value.$el.closeSlidingItems();
                    }
                }
                if (icons.value != null) {
                    icons.value[index].$el.style.visibility = 'hidden';
                }
                showCheckmark(index);

                await AppAPI.setReadPushNotification(id);
                setTimeout(async () => {
                    await loadNotifications();
                    if (item.value != null && icons.value != null) {
                        if (item.value[index]) {
                            try {
                                item.value[index].$el.style.visibility = 'visible';
                                icons.value[index].$el.style.visibility = 'visible';
                            } catch (error) {
                                console.log('No more notifications. ' + error);
                            }
                        }
                    }
                }, waitTimer.value);
            };

            const markAllRead = async () => {
                const response = await advancedAlert(
                    'Warning',
                    '',
                    'Are you sure you want to mark all notifications as read?',
                    [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: 'Confirm',
                            role: 'confirm',
                        },
                    ]
                );
                if (response.role == 'cancel') {
                    return;
                }
                await AppAPI.setAllReadPushNotification();
                await loadNotifications();
                if (store.PushNotificationList.length > 0) {
                    markAllRead();
                }
            };

            const openDeviceSettings = async () => {
                AppFunctions.openDeviceSettings();
            };

            const getPlatform = () => {
                return Capacitor.getPlatform();
            };

            const showCheckmark = (index: any) => {
                checkmark.value[index].style.opacity = 1;

                setTimeout(function () {
                    checkmark.value[index].style.opacity = 0;
                }, waitTimer.value - 100); // Change this value to adjust the display time in milliseconds
            };

            const openSupport = () => {
                Intercom.displayArticle({ articleId: '8530503' });
                //Intercom.displayArticle({ articleId: '7130238' });
                //Intercom.displayMessenger();
            };

            const openDeviceModal = async () => {
                const device = await AppAPI.getDeviceById(store.PushNotificationToken).catch((e) => {
                    console.log(e);
                });
                if (device == undefined) {
                    basicAlert(
                        'Device Not Found',
                        '',
                        'Current device has not been added to your Lodgix account. Enable push notifications in device settings first to add device to account.'
                    );
                    return;
                }
                await ModalController.deviceEditModal(device.data);
            };

            onMounted(async () => {
                //const loadingService = await showLoading('Loading...');
                try {
                    await loadNotifications();
                    //hideLoading(loadingService);
                } catch (error) {
                    //hideLoading(loadingService);
                }
            });

            watch(
                () => store.PushNotificationList,
                () => {
                    try {
                        if (store.PushNotificationList.length > 0) {
                            showNotificationList.value = true;
                            if (bellLabel.value) {
                                bellLabel.value.style.display = 'none';
                            }
                            // bellLabel.value.style.display = 'none';
                        } else {
                            showNotificationList.value = false;
                            if (bellLabel.value) {
                                bellLabel.value.style.display = 'block';
                            }
                            // bellLabel.value.style.display = 'block';
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            );

            return {
                waitTimer,
                showNotificationList,
                bellLabel,
                notifications,
                ionItem,
                icon,
                notificationList,
                checkmark,
                chevronForwardOutline,
                alertCircleOutline,
                checkmarkCircleOutline,
                ellipse,
                checkmarkOutline,
                chevronUp,
                document,
                colorPalette,
                cog,
                settings,
                reload,
                store,
                information,
                ModalController,
                openDeviceModal,
                basicAlert,
                openNotification,
                handleRefresh,
                formatDate,
                markAsRead,
                markAllRead,
                openDeviceSettings,
                getPlatform,
                openSupport,
            };
        },
    });
</script>

<style scoped>
    ion-label {
        color: black;
    }

    .bell-label {
        opacity: 1;
        z-index: -1;
        animation: fade-in 0.5s forwards;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .checkmark {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 99;
        transform: translate(-50%, -50%);
        opacity: 0;
        font-size: 100px;
        color: black;
        transition: opacity 0.5s ease-in-out;
    }
</style>

import { errorToast, successToast } from '@/services/Toast';
import ControlPanelModal from '@/components/reservation/modals/ControlPanelModal.vue';
import NotificationModal from '@/components/notifications/modals/NotificationModal.vue';
import GuestModal from '@/components/reservation/modals/GuestModal.vue';
import PropertySelectionModal from '@/components/reservation/modals/PropertySelectionModal.vue';
import ChecklistModal from '@/components/housekeeper/modals/ChecklistModal.vue';
import { modalController } from '@ionic/vue';
import { hideLoading, showLoading } from '@/services/Loading';
import AppAPI from '@/services/AppAPI';
import { format, add } from 'date-fns';
import AppFunctions from '@/services/AppFunctions';
import { useStore } from '@/store/store';
import RulesOverrideModalVue from '@/components/reservation/modals/RulesOverrideModal.vue';
import WidgetEditModalVue from '@/components/widgets/modals/WidgetEditModal.vue';
import DeviceEditModal from '@/components/settings/modals/DeviceEditModal.vue';
import WorkOrderDetailsModal from '@/components/workOrders/WorkOrderDetailsModal.vue';
import WorkOrderDetailsMaterialExpenseModal from '@/components/workOrders/WorkOrderDetailsMaterialExpenseModal.vue';
import ReservationFilterModal from '@/components/reservation/modals/ReservationFilterModal.vue';

export default {
    async controlPanelModal(invoiceNumber: string) {
        const store = useStore();
        const loadingService = await showLoading('Loading...');
        try {
            const reservation = await AppAPI.getReservationByInv(invoiceNumber);
            //console.log(reservation.data)
            const createOnDate = format(new Date(reservation.data.created), 'MM/dd/yyyy');

            const modal = await modalController.create({
                component: ControlPanelModal,
                componentProps: {
                    id: reservation.data.guest.id,
                    startDate: reservation.data.from_date,
                    endDate: reservation.data.to_date,
                    properties: reservation.data.properties,
                    status: reservation.data.state,
                    reservationId: invoiceNumber,
                    guest: reservation.data.guest,
                    stayType: reservation.data.stay_type,
                    createdDate: createOnDate,
                    guests: reservation.data.guests,
                    children: reservation.data.children,
                    checkInTime: reservation.data.check_in,
                    checkOutTime: reservation.data.check_out,
                    total: reservation.data.total,
                    confirmationAmount: reservation.data.confirmation_amount,
                    totalDeposits: reservation.data.total_with_deposits,
                    paid: reservation.data.paid,
                    substate: reservation.data.substate,
                    bookingMethodName: reservation.data.booking_method_name,
                    conversationId: reservation.data?.conversation_id || '',
                },
            });

            hideLoading(loadingService);
            modal.present();

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { data, role } = await modal.onWillDismiss();
            if (role === 'confirm') {
                //console.log(data);
            }
        } catch (error) {
            hideLoading(loadingService);
            errorToast('Reservation not found');
            return;
        }
    },
    async notificationModal(item: any) {
        const store = useStore();
        store.changePushNotificationOpenId('');
        const modal = await modalController.create({
            component: NotificationModal,
            componentProps: {
                item: item,
            },
        });
        modal.present();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, role } = await modal.onWillDismiss();
        if (role === 'cancel') {
            store.changePushNotificationOpenId('');
        } else if (role == 'confirm') {
            store.changePushNotificationOpenId('');
            await AppAPI.setReadPushNotification(item.id);
            await successToast('Notification marked as read');
        }
        await AppFunctions.updatePushNotificationSystem();
    },
    async guestModal() {
        const modal = await modalController.create({
            component: GuestModal,
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            return data;
        } else {
            return null;
        }
    },
    async propertyListModal(isBlock: boolean) {
        const modal = await modalController.create({
            component: PropertySelectionModal,
            componentProps: {
                isBlock: isBlock,
            },
        });

        modal.present();

        const { data, role } = await modal.onWillDismiss();
        if (role === 'confirm') {
            //console.log(data)
        }
        if (role === 'cancel') {
            if (data.error) {
                return 'error';
            }
        }
    },

    async checklistModal(checklistData: any) {
        const modal = await modalController.create({
            component: ChecklistModal,
            componentProps: {
                checklistData: checklistData,
            },
        });
        modal.present();
        const { data, role } = await modal.onWillDismiss();
    },

    async rulesOverrideModal(errors: any) {
        const modal = await modalController.create({
            component: RulesOverrideModalVue,
            componentProps: {
                errors: errors.response.data.errors,
            },
        });
        modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async customRulesOverrideModal(errors: any) {
        const modal = await modalController.create({
            component: RulesOverrideModalVue,
            componentProps: {
                errors: errors,
            },
        });
        modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async widgetEditModal() {
        const modal = await modalController.create({
            component: WidgetEditModalVue,
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async deviceEditModal(device: any) {
        const modal = await modalController.create({
            component: DeviceEditModal,
            componentProps: {
                device: device,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async workOrderDetailsModal(workOrderId: string) {
        const modal = await modalController.create({
            component: WorkOrderDetailsModal,
            componentProps: {
                workOrderId: workOrderId,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async workOrderDetailsMaterialExpenseModal(workOrderId: any, workOrderData: any) {
        const modal = await modalController.create({
            component: WorkOrderDetailsMaterialExpenseModal,
            componentProps: {
                workOrderId: workOrderId,
                data: workOrderData,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },

    async reservationFilterModal(currentFilters: any) {
        const modal = await modalController.create({
            component: ReservationFilterModal,
            componentProps: {
                currentFilters: currentFilters,
            },
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();
        const repsonse = {
            data: data,
            role: role,
        };
        return repsonse;
    },
};

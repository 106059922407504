import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-40abb0b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","color":"black","padding":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Notification Details" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_label, { style: {"font-size":"large","font-weight":"700"} }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.subject), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_label, { "text-wrap": "" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.item.date)), 1)
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  (_ctx.showChatButton)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        class: "modal-footer-button",
                        color: "primary",
                        onClick: _ctx.chatButton
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View In Unified Inbox")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.showWorkOrderButton)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        class: "modal-footer-button",
                        color: "primary",
                        onClick: _ctx.workOrderButton
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View Work Order")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Back")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Mark As Read")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { style: {"white-space":"pre-wrap","overflow-wrap":"break-word"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.body), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { useStore } from '@/store/store';

export function housekeeperGroup() {
    return checkRoles(['Cleaning Staff']);
}

export function intercomSupportAccess() {
    return checkRoles(['Subscriber', 'Super User', 'Front Desk']);
}

export function adminAppWelcomePopup() {
    return checkRoles(['Subscriber', 'Super User', 'Front Desk']);
}

export function ownerGroup() {
    return checkRoles(['Owner Default', 'Owner Minimal', 'Owner Enhanced', 'Owner No Work Orders']);
}

export function calendarAccess() {
    return checkScope(['calendar:read', 'calendar:write']);
}

export function unifiedInboxAccess() {
    return checkScope(['inbox:read', 'inbox:write']);
}

export function widgetAccess() {
    return checkRoles(['Subscriber', 'Super User', 'Front Desk']);
}

export function reservationAccess() {
    return checkScope(['reservation:read', 'reservation:write']);
}

export function workOrderAccess() {
    return checkScope(['workorder:read']);
}

export function checkScope(scope: string[]) {
    const store = useStore();
    if (scope.length == 0) {
        return true;
    }
    for (let i = 0; i < scope.length; i++) {
        if (store.scope.includes(scope[i])) {
            if (scope[i] == 'calendar:read' || scope[i] == 'calendar:write') {
                const calOptions = store.calendarTapeOptions as any;
                if (calOptions.access_calendar_tape) {
                    return true;
                }
            } else {
                return true;
            }
        }
    }
    return false;
}

export function checkRoles(roles: string[]) {
    const store = useStore();
    if (roles.length == 0) {
        return true;
    }
    for (let i = 0; i < roles.length; i++) {
        if (store.LoginType == roles[i]) {
            return true;
        }
    }
    return false;
}

<template>
    <ion-card style="margin-bottom: 10px; margin-top: 10px">
        <ion-card-content
            style="padding: 5px; background-image: linear-gradient(to right, rgb(142, 63, 145), rgb(214, 99, 219))"
        >
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-label style="font-size: 20px; font-weight: 800; color: white; display: flex"
                            >{{ store.occupancyAmount }}%</ion-label
                        >
                        <ion-row>
                            <ion-label style="font-size: 20px; font-weight: 800; color: white">Occupancy</ion-label>
                        </ion-row>
                    </ion-col>
                    <div style="width: 75px; height: 50px">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fa"
                            data-icon="hotel"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            class="svg-inline--fa fa-hotel fa-w-18 fa-3x"
                            style="color: white"
                        >
                            <path
                                fill="currentColor"
                                d="M560 64c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h15.98v384H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h240v-80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v80h240c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-16V64h16zm-304 44.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm0 96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm-128-96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zM179.2 256h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8zM192 384c0-53.02 42.98-96 96-96s96 42.98 96 96H192zm256-140.8c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-96c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4z"
                                class=""
                            ></path>
                        </svg>
                    </div>
                </ion-row>
            </ion-grid>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { useStore } from '@/store/store';
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            return {
                store,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        box-shadow: none;
    }
</style>

<template>
    <modal-base-layout ref="modalBaseLayoutRef" title="Widget Settings">
        <ion-list lines="none">
            <ion-item>
                <ion-toggle v-model="inquiriesWidgetValue">Inquiries Widget</ion-toggle>
            </ion-item>
            <ion-item>
                <ion-toggle v-model="pendingWidgetValue">Pending Widget</ion-toggle>
            </ion-item>
            <ion-item>
                <ion-toggle v-model="arrivalsWidgetValue">Arrivals Widget</ion-toggle>
            </ion-item>
            <ion-item>
                <ion-toggle v-model="departuresWidgetValue">Departures Widget</ion-toggle>
            </ion-item>
            <ion-item>
                <ion-toggle v-model="occupancyWidgetValue">Occupancy Widget</ion-toggle>
            </ion-item>
        </ion-list>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-label style="font-size: 14px"
                            ><span style="font-weight: bold">Notice</span>: These settings are saved on device and are
                            not synced between you devices on your account.</ion-label
                        >
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="modalBaseLayoutRef.cancel()"
                            >Close</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { useStore } from '@/store/store';
    import { defineComponent, ref, watch } from 'vue';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const modalBaseLayoutRef = ref();
            const inquiriesWidgetValue = ref(store.showInquiriesWidget);
            const pendingWidgetValue = ref(store.showPendingWidget);
            const arrivalsWidgetValue = ref(store.showArrivalsWidget);
            const departuresWidgetValue = ref(store.showDeparturesWidget);
            const occupancyWidgetValue = ref(store.showOccupancyWidget);

            const setupWatch = (localValue: any, storeValue: any, storeMethod: any) => {
                watch(
                    () => localValue.value,
                    (value) => {
                        storeMethod(value);
                    }
                );

                watch(
                    () => storeValue,
                    (value) => {
                        localValue.value = value;
                    }
                );
            };

            setupWatch(inquiriesWidgetValue, store.showInquiriesWidget, store.changeShowInquiriesWidget);
            setupWatch(pendingWidgetValue, store.showPendingWidget, store.changeShowPendingWidget);
            setupWatch(arrivalsWidgetValue, store.showArrivalsWidget, store.changeShowArrivalsWidget);
            setupWatch(departuresWidgetValue, store.showDeparturesWidget, store.changeShowDeparturesWidget);
            setupWatch(occupancyWidgetValue, store.showOccupancyWidget, store.changeShowOccupancyWidget);

            return {
                modalBaseLayoutRef,
                inquiriesWidgetValue,
                pendingWidgetValue,
                arrivalsWidgetValue,
                departuresWidgetValue,
                occupancyWidgetValue,
            };
        },
    });
</script>

<style scoped>
    ion-item {
        --background: transparent;
        padding: 0px !important;
        margin: 0px;
    }

    ion-list {
        --background: transparent;
        padding: 0px !important;
        margin: 5px;
    }
</style>

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_card_header, {
      class: "ldx-section-start",
      style: {"padding-bottom":"0px"}
    }, {
      default: _withCtx(() => [
        (_ctx.store.reservationSelectedPropertyId == '')
          ? (_openBlock(), _createBlock(_component_ion_label, {
              key: 0,
              style: {"font-size":"14px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Click field below to search properties")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationSelectedPropertyId != '')
          ? (_openBlock(), _createBlock(_component_ion_label, {
              key: 1,
              style: {"font-size":"14px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Click field below to change property")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationSelectedPropertyId == '')
          ? (_openBlock(), _createBlock(_component_ion_card_title, { key: 2 }, {
              default: _withCtx(() => [
                _createTextVNode("Select Property")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.store.reservationSelectedPropertyId != '')
          ? (_openBlock(), _createBlock(_component_ion_card_title, { key: 3 }, {
              default: _withCtx(() => [
                _createTextVNode("Selected Property")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.store.reservationSelectedPropertyId == '' && _ctx.store.reservationEndDate == '')
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 0,
          class: "ldx-reservation-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, {
              style: {"cursor":"pointer"},
              lines: "none",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.basicAlert('', '', 'Please select a start and end date before seaching properties.')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Select Property")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_icon, {
                  icon: _ctx.searchOutline,
                  slot: "end"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.store.reservationSelectedPropertyId == '' && _ctx.store.reservationEndDate != '')
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 1,
          class: "ldx-reservation-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, {
              style: {"cursor":"pointer"},
              lines: "none",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { style: {"color":"red"} }, {
                  default: _withCtx(() => [
                    _createTextVNode("* Select Property")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_icon, {
                  color: "danger",
                  icon: _ctx.searchOutline,
                  slot: "end"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.store.reservationSelectedPropertyId != '')
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 2,
          class: "ldx-reservation-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, {
              style: {"cursor":"pointer"},
              lines: "none",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openModal()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { style: {"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.store.reservationSelectedProperty), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_icon, {
                  color: "success",
                  icon: _ctx.checkmarkCircleOutline,
                  slot: "end"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
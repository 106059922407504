import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"color":"black","background":"rgb(239, 246, 255)","overflow-y":"scroll","height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_housekeeper_component = _resolveComponent("housekeeper-component")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showHousekeeper)
          ? (_openBlock(), _createBlock(_component_housekeeper_component, {
              key: 0,
              onApiFailed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.oauthFailed($event))),
              onPresentToast: _ctx.toast,
              onOpenChecklist: _ctx.openChecklist,
              accessToken: _ctx.accessToken,
              urlBase: _ctx.urlBase,
              updateTrigger: _ctx.updateTrigger,
              language: _ctx.store.lang,
              isApp: ""
            }, null, 8, ["onPresentToast", "onOpenChecklist", "accessToken", "urlBase", "updateTrigger", "language"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
<template>
    <div>
        <ion-card-header class="ldx-section-start" style="padding-bottom: 0px">
            <ion-label v-if="store.reservationSelectedPropertyId == ''" style="font-size: 14px"
                >Click field below to search properties</ion-label
            >
            <ion-label v-if="store.reservationSelectedPropertyId != ''" style="font-size: 14px"
                >Click field below to change property</ion-label
            >
            <ion-card-title v-if="store.reservationSelectedPropertyId == ''">Select Property</ion-card-title>
            <ion-card-title v-if="store.reservationSelectedPropertyId != ''">Selected Property</ion-card-title>
        </ion-card-header>

        <ion-list
            v-if="store.reservationSelectedPropertyId == '' && store.reservationEndDate == ''"
            class="ldx-reservation-form"
        >
            <ion-item
                style="cursor: pointer"
                lines="none"
                @click="basicAlert('', '', 'Please select a start and end date before seaching properties.')"
            >
                <ion-label>Select Property</ion-label>
                <ion-icon :icon="searchOutline" slot="end"></ion-icon>
            </ion-item>
        </ion-list>

        <ion-list
            v-if="store.reservationSelectedPropertyId == '' && store.reservationEndDate != ''"
            class="ldx-reservation-form"
        >
            <ion-item style="cursor: pointer" lines="none" @click="openModal()">
                <ion-label style="color: red">* Select Property</ion-label>
                <ion-icon color="danger" :icon="searchOutline" slot="end"></ion-icon>
            </ion-item>
        </ion-list>

        <ion-list v-if="store.reservationSelectedPropertyId != ''" class="ldx-reservation-form">
            <ion-item style="cursor: pointer" lines="none" @click="openModal()">
                <ion-label style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{
                    store.reservationSelectedProperty
                }}</ion-label>
                <ion-icon color="success" :icon="checkmarkCircleOutline" slot="end"></ion-icon>
            </ion-item>
        </ion-list>
    </div>
</template>

<script lang="ts">
    import { searchOutline, closeCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
    import { defineComponent } from 'vue';
    import ModalController from '@/services/ModalController';
    import { useStore } from '@/store/store';
    import { basicAlert } from '@/services/AlertController';

    export default defineComponent({
        props: ['isMinimal'],
        components: {},
        setup(props) {
            const store = useStore();

            const openModal = async () => {
                const result = await ModalController.propertyListModal(props.isMinimal);
                if (result == 'error') {
                    basicAlert('', '', 'No properties were found.  Please adjust your search parameters.');
                }
            };

            return {
                openModal,
                basicAlert,
                store,
                searchOutline,
                closeCircleOutline,
                checkmarkCircleOutline,
            };
        },
    });
</script>

<style scoped>
    .ldx-section-start {
        padding-top: 0;
        padding-left: 0;
    }

    .ldx-section-start ion-card-title {
        font-size: 20px;
    }
    .ldx-reservation-form ion-item::part(native) {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;
    }
    .ldx-reservation-form ion-label {
        margin: 0;
    }

    .ldx-reservation-form ion-icon {
        font-size: 14px;
    }

    .ldx-reservation-form .select-icon {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-reservation-form ion-select::part(placeholder),
    .ldx-reservation-form ion-select::part(text),
    .ldx-reservation-form ion-select::part(icon) {
        color: #000000;
        opacity: 1;
    }
    .ldx-reservation-form ion-select::part(icon) {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-reservation-form ion-icon.ldx-alt-icon {
        background: var(--ion-color-primary);
        color: #ffffff;
        padding: 0.5rem;
        font-size: 15px;
        border-radius: 5px;
        margin: 0;
    }
</style>

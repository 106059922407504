<template>
    <WidgetContentListBase
        :title="'Inquiries'"
        :headerColor="'rgb(219, 99, 99)'"
        :list="dataArray"
        :totalCount="dataCount"
        :noDataLabel="'No Inquiries'"
        @start="toStart"
        @end="toEnd"
        @back="backButton"
        @next="nextButton"
    >
        <ion-list v-for="(item, index) in dataArray" :key="index" :value="item" style="padding: 0px; margin: 0px">
            <ion-item lines="full">
                <ion-grid style="padding: 0px; margin: 0px; padding-bottom: 8px; padding-top: 8px; width: 100%">
                    <ion-col style="padding: 0px; margin: 0px">
                        <ion-row style="padding: 1px; margin: 0px; width: 100%">
                            <ion-label
                                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 100%"
                                >{{ dataArray[index].guest.short_name }}</ion-label
                            >
                        </ion-row>
                        <ion-row style="padding: 1px; margin: 0px">
                            <ion-label
                                style="
                                    font-size: smaller;
                                    font-weight: 500;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 100%;
                                "
                                >{{
                                    dataArray[index].rental_property_name
                                        ? dataArray[index].rental_property_name
                                        : 'Not Available'
                                }}</ion-label
                            >
                        </ion-row>
                    </ion-col>
                </ion-grid>
                <div style="padding: 0px; margin: 0px" slot="end">
                    <ion-icon
                        class="ldx-large-icon"
                        v-if="dataArray[index].conversation_link && unifiedInboxAccess()"
                        @click="openChat(dataArray[index].conversation_link)"
                        :icon="chatbubblesOutline"
                        style="margin-top: 7px; cursor: pointer"
                    >
                    </ion-icon>
                </div>
            </ion-item>
        </ion-list>
    </WidgetContentListBase>
</template>

<script lang="ts">
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import AppAPI from '@/services/AppAPI';
    import { mailOutline, callOutline, ellipsisVerticalOutline, chatbubblesOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import ModalController from '@/services/ModalController';
    import { useIonRouter } from '@ionic/vue';
    import { unifiedInboxAccess } from '@/types/accessGroups';
    import WidgetContentListBase from './components/WidgetContentListBase.vue';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {
            WidgetContentListBase,
        },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();

            const offset = ref(0);
            const listSize = ref(8);
            const dataCount = ref(0);
            const dataArray = ref([] as any[]);

            const openChat = (chatLink: string) => {
                let chatId = chatLink.split('/')[chatLink.split('/').length - 1];
                AppFunctions.openChat(chatId);
            };

            const openModal = async (reservationId: string) => {
                await ModalController.controlPanelModal(reservationId);
            };

            const getInformattion = async () => {
                let data = await AppAPI.getInquiriesWidgetContent(offset.value.toString());
                if (data) {
                    dataCount.value = data.data.total_records;
                    dataArray.value = data.data.data;
                }
            };

            const backButton = () => {
                if (offset.value <= 0) {
                    return;
                } else {
                    if (offset.value - listSize.value < 0) {
                        offset.value = 0;
                        getInformattion();
                    } else {
                        offset.value = offset.value - listSize.value;
                        getInformattion();
                    }
                }
            };

            const nextButton = () => {
                if (offset.value + listSize.value >= dataCount.value) {
                    return;
                } else {
                    offset.value = offset.value + listSize.value;
                    getInformattion();
                }
            };

            const toStart = () => {
                offset.value = 0;
                getInformattion();
            };

            const toEnd = () => {
                if (dataCount.value < listSize.value) {
                    return;
                }
                offset.value = dataCount.value - listSize.value;
                getInformattion();
            };

            watch(
                () => store.inquiryDataArray,
                async () => {
                    dataArray.value = store.inquiryDataArray;
                }
            );

            watch(
                () => store.inquiryDataCount,
                async () => {
                    dataCount.value = store.inquiryDataCount;
                }
            );

            onMounted(async () => {
                dataArray.value = store.inquiryDataArray;
                dataCount.value = store.inquiryDataCount;
            });

            return {
                ionRouter,
                mailOutline,
                callOutline,
                ellipsisVerticalOutline,
                chatbubblesOutline,
                offset,
                listSize,
                dataCount,
                dataArray,
                unifiedInboxAccess,
                openChat,
                openModal,
                getInformattion,
                backButton,
                nextButton,
                toStart,
                toEnd,
            };
        },
    });
</script>

<style scoped></style>

<template>
    <ion-card style="margin-bottom: 10px; margin-top: 10px">
        <ion-card-content
            style="padding: 5px; background-image: linear-gradient(to right, rgb(63, 142, 145), rgb(99, 214, 219))"
        >
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-label style="font-size: 20px; font-weight: 800; color: white; display: flex">{{
                            store.pendingAmount
                        }}</ion-label>
                        <ion-row>
                            <ion-label style="font-size: 20px; font-weight: 800; color: white">Pending</ion-label>
                        </ion-row>
                    </ion-col>
                    <ion-icon
                        style="width: 70px; height: 70px; margin-right: 0px; color: white"
                        :icon="hourglassOutline"
                    ></ion-icon>
                    <div hidden style="width: 55px; height: 50px; margin-right: 5px">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fa"
                            data-icon="user"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-user fa-w-14 fa-3x"
                            style="color: white"
                        >
                            <path
                                fill="currentColor"
                                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                                class=""
                            ></path>
                        </svg>
                    </div>
                </ion-row>
            </ion-grid>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { hourglassOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            return {
                hourglassOutline,
                store,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        box-shadow: none;
    }
</style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"padding":"0px","margin-right":"0px","margin-left":"10px"},
  slot: "end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_WidgetContentListBase = _resolveComponent("WidgetContentListBase")!

  return (_openBlock(), _createBlock(_component_WidgetContentListBase, {
    title: 'Departures',
    headerColor: 'rgb(226, 179, 152)',
    list: _ctx.dataArray,
    totalCount: _ctx.dataCount,
    noDataLabel: 'No Departures Today',
    onStart: _ctx.toStart,
    onEnd: _ctx.toEnd,
    onBack: _ctx.backButton,
    onNext: _ctx.nextButton
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataArray, (item, index) => {
        return (_openBlock(), _createBlock(_component_ion_list, {
          key: index,
          value: item,
          style: {"padding":"0px","margin":"0px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { lines: "full" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_grid, { style: {"padding":"0px","margin":"0px","padding-bottom":"8px","padding-top":"8px","width":"100%"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { style: {"padding":"0px","margin":"0px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_row, { style: {"padding":"1px","margin":"0px","width":"100%"} }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","width":"100%"} }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.dataArray[index].reservation?.guest_name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_row, { style: {"padding":"1px","margin":"0px"} }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { style: {"font-size":"smaller","font-weight":"500","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","width":"100%"} }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.dataArray[index].property_name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_ion_icon, {
                    class: "ldx-large-icon",
                    onClick: ($event: any) => (_ctx.openModal(_ctx.dataArray[index].reservation.id.toString())),
                    icon: _ctx.ellipsisVerticalOutline,
                    style: {"margin-top":"6px","cursor":"pointer"}
                  }, null, 8, ["onClick", "icon"])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["list", "totalCount", "onStart", "onEnd", "onBack", "onNext"]))
}
<template>
    <modal-base-layout title="Select Property" background="rgb(239, 246, 255)">
        <template #header>
            <div style="display: flex; width: 100%">
                <div v-if="!isSearchOpen" style="display: flex; align-items: center; width: 100%">
                    <p style="font-weight: 700; font-size: 15px; margin: 0px; color: black; padding-left: 5px">
                        Available:
                        <span style="font-weight: 400">{{ availablePropertyList.length }} Properties</span><br />
                        Dates:
                        <span style="font-weight: 400"
                            >{{ formatDate(store.reservationStartDate) }} -
                            {{ formatDate(store.reservationEndDate) }}</span
                        >
                    </p>
                </div>

                <div v-if="isSearchOpen" style="display: flex; align-items: center; width: 100%">
                    <ion-searchbar
                        placeholder="Search Properties"
                        :debounce="0"
                        show-clear-button="never"
                        @ionInput="searchChange($event)"
                        style="padding: 0px 5px"
                    ></ion-searchbar>
                </div>
                <ion-button class="modal-header-button" v-if="!isSearchOpen" @click="searchButton()"
                    ><ion-icon :src="searchCircle"></ion-icon
                ></ion-button>
                <ion-button class="modal-header-button" v-if="isSearchOpen" @click="searchButton()">
                    <ion-icon :src="closeCircle"></ion-icon>
                </ion-button>
                <ion-button @click="changeOrder()" class="modal-header-button">
                    <ion-icon :style="iconStyle" :icon="filter"></ion-icon>
                </ion-button>
            </div>
        </template>

        <div class="ldx-property-list">
            <template v-for="(item, index) in availablePropertyList">
                <ion-card
                    mode="ios"
                    class="ldx-property-card"
                    v-if="searchPropertyMatch(index, item)"
                    @click="selectProperty(index, item)"
                    :key="index"
                    :value="item"
                    style="cursor: pointer"
                >
                    <ion-img
                        v-if="item['thumbnail_url'] != null && item['thumbnail_url'] != ''"
                        :src="item['thumbnail_url']"
                    ></ion-img>
                    <img v-else src="@/assets/img/no_image.png" />

                    <ion-card-header style="text-align: left">
                        <ion-card-subtitle
                            v-if="item['address']['short_name'] != null && item['address']['short_name'] != ''"
                        >
                            <ion-icon :icon="locationSharp"></ion-icon>
                            {{ item['address']['short_name'] }}
                        </ion-card-subtitle>
                        <ion-card-title
                            color="primary"
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                            >{{ item['name'] }}</ion-card-title
                        >
                    </ion-card-header>

                    <ion-card-content>
                        <span class="ldx-prop-price" style="width: 100px; text-align: center" v-if="!isBlock">
                            <strong>{{ getDisplayMoney(getPropertyFees(item['id']).total) }}</strong> Total
                        </span>

                        <div class="ldx-quick-amenities">
                            <div class="ldx-quick-amenity">
                                <img src="@/assets/icons/bed-icon.png" />
                                <span>{{ item['bedrooms'] }}</span>
                            </div>
                            <div class="ldx-quick-amenity">
                                <img src="@/assets/icons/bath-icon.png" />
                                <span>{{ item['baths'] }}</span>
                            </div>
                            <div class="ldx-quick-amenity">
                                <img src="@/assets/icons/guest-icon.png" />
                                <span>{{ item['max_guests'] }}</span>
                            </div>
                            <div class="ldx-quick-amenity">
                                <img v-if="item['pets_allowed']" src="@/assets/icons/pet-icon.png" />
                                <img v-else src="@/assets/icons/nopet-icon.png" />
                            </div>
                        </div>
                    </ion-card-content>

                    <ion-chip
                        style="
                            max-width: -webkit-fill-available;
                            max-height: 40px;
                            height: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                        "
                        v-if="!isSelectedProperty(index, item)"
                    >
                        <span> Select Property </span>
                    </ion-chip>
                    <ion-chip
                        style="
                            background: #4c81f2;
                            max-width: -webkit-fill-available;
                            max-height: 40px;
                            height: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                        "
                        v-if="isSelectedProperty(index, item)"
                    >
                        <span> Property Selected </span>
                    </ion-chip>
                </ion-card>
            </template>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="cancel">Cancel</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import AppAPI from '@/services/AppAPI';
    import { useStore } from '@/store/store';
    import { modalController } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref, watch } from 'vue';
    import {
        searchOutline,
        locationSharp,
        checkmarkCircleOutline,
        closeCircleOutline,
        informationCircleOutline,
        arrowBackOutline,
        searchCircle,
        closeCircle,
        home,
        filter,
    } from 'ionicons/icons';
    import { format } from 'date-fns';
    import { hideLoading, showLoading } from '@/services/Loading';
    import ModalController from '@/services/ModalController';

    export default defineComponent({
        props: ['isBlock'],
        name: 'PropModal',
        components: {},
        setup(props) {
            const store = useStore();
            const order = ref('ascending');
            const selectedPropertyId = ref(store.reservationSelectedPropertyId);
            const availablePropertyList = ref([] as any);
            const searchPropertyText = ref('');
            const selectedPropertyName = ref(store.reservationSelectedProperty);
            const thumbnailUrl = ref(store.reservationThumbnailUrl);
            const PropertyListQuotes = ref(store.reservationPropertyListQuotes);
            const AvailablePropertyListHasOwner = ref(store.reservationPropertyListHasOwner);
            const error = ref(false);
            const isSearchOpen = ref(false);

            const formatDate = computed(() => (date: string) => {
                if (date == '') {
                    return 'Empty Date';
                }
                var fromDateString = format(new Date(date), 'MM/dd/yyyy');
                return fromDateString;
            });

            const iconStyle = computed(() => {
                return {
                    transform: order.value === 'ascending' ? 'scaleY(-1)' : 'none',
                };
            });

            const cancel = () => {
                return modalController.dismiss(
                    {
                        error: error.value,
                    },
                    'cancel'
                );
            };

            const saveData = () => {
                store.changeReservationSelectedProperty(selectedPropertyName.value);
                store.changeReservationSelectedPropertyId(selectedPropertyId.value);
                store.changeReservationPropertyListQuotes(getPropertyFees(selectedPropertyId.value));
                store.changeReservationThumbnailUrl(thumbnailUrl.value);
                store.changeReservationPropertyListHasOwner(AvailablePropertyListHasOwner.value);
            };

            const confirm = () => {
                saveData();
                return modalController.dismiss(
                    {
                        //
                    },
                    'confirm'
                );
            };

            const changeOrder = () => {
                if (order.value == 'descending') {
                    order.value = 'ascending';
                } else {
                    order.value = 'descending';
                }
            };

            const getDateToString = (date: string) => {
                if (date == '') {
                    return 'Select Date';
                }
                var fromDateString = format(new Date(date), 'MM/dd/yyyy');
                return fromDateString;
            };

            const isSelectedProperty = (index: any, item: any) => {
                if (selectedPropertyId.value == item['id']) {
                    return true;
                } else {
                    return false;
                }
            };

            const searchPropertyMatch = (index: any, item: any) => {
                if (searchPropertyText.value != '') {
                    if (
                        item['name'].toLowerCase().includes(searchPropertyText.value.toLowerCase()) ||
                        item['id'] == selectedPropertyId.value
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            };

            const selectProperty = (index: any, item: any) => {
                selectedPropertyId.value = item['id'];
                selectedPropertyName.value = item['name'];
                thumbnailUrl.value = item['thumbnail_url'];
                if (thumbnailUrl.value == '' || thumbnailUrl.value == null) {
                    thumbnailUrl.value = 'https://www.lodgix.com/static/images/no_image.png';
                }
                confirm();
            };

            const getPropertyFees = (propertyId: any) => {
                var str = PropertyListQuotes.value[parseInt(propertyId)];
                var newstr = JSON.parse(JSON.stringify(str));
                return newstr;
            };

            const getDisplayMoney = (value: number) => {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: store.userCurrency,
                });
                return format.format(value);
            };

            const getPropertiesForDateRange = async () => {
                error.value = false;
                if (store.reservationStartDate == '' || store.reservationEndDate == '') {
                    return;
                }
                //console.log("GET PROPERTIES")
                const loadingService = await showLoading('Loading...');
                try {
                    var fromDateString = format(new Date(store.reservationStartDate), 'yyyy-MM-dd');
                    var toDateString = format(new Date(store.reservationEndDate), 'yyyy-MM-dd');

                    var tmpAdults = '1';
                    var tmpChildren = '0';
                    var tmpPets = '0';

                    //console.log("ADULTS, CHILDREN, PETS: " + this.Adults + ", " + this.Children + ", " + this.Pets)

                    if (store.reservationAdults != '' && store.reservationAdults != null) {
                        tmpAdults = store.reservationAdults;
                    }
                    if (store.reservationChildren != '' && store.reservationChildren != null) {
                        tmpChildren = store.reservationChildren;
                    }
                    if (store.reservationPets != '' && store.reservationPets != null) {
                        tmpPets = store.reservationPets;
                    }
                    if (store.reservationStartDate != '' && store.reservationEndDate != '') {
                        console.log(fromDateString + ' -- ' + toDateString);
                        console.log(tmpAdults + ', ' + tmpChildren + ', ' + tmpPets);
                        var response = AppAPI.getProperties(
                            fromDateString,
                            toDateString,
                            tmpAdults,
                            tmpChildren,
                            tmpPets,
                            null
                        );

                        response
                            .then((value) => {
                                var tmpListProp = value.data.available_properties;
                                var tmpListPropOwner = value.data.has_owner;
                                var tmpListQuotes = value.data.quotes;
                                var tmpOrder = order.value;

                                tmpListProp.sort(function (a: any, b: any) {
                                    if (tmpOrder == 'ascending') {
                                        return a['bedrooms'] - b['bedrooms'];
                                    } else {
                                        return b['bedrooms'] - a['bedrooms'];
                                    }
                                });

                                availablePropertyList.value = tmpListProp;
                                AvailablePropertyListHasOwner.value = tmpListPropOwner;
                                PropertyListQuotes.value = tmpListQuotes;

                                //move to property selection instantly now
                                if (availablePropertyList.value.length > 0) {
                                    hideLoading(loadingService);
                                } else {
                                    error.value = true;
                                    hideLoading(loadingService);
                                    clearDates();
                                    cancel();
                                }
                            })
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            .catch(async (error) => {
                                hideLoading(loadingService);
                                //console.log('ERROR: ' + error.response.data);
                            });
                    } else {
                        hideLoading(loadingService);
                    }

                    //hideLoading(loadingService)
                } catch (error) {
                    hideLoading(loadingService);
                    //console.log(error);
                }
            };

            const clearDates = () => {
                selectedPropertyId.value = '';
                selectedPropertyName.value = '';
                store.changeReservationStartDate('');
                store.changeReservationEndDate('');
                store.changeReservationSelectedPropertyId('');
                store.changeReservationSelectedProperty('');
                store.changeReservationThumbnailUrl('');
                store.changeReservationPropertyListHasOwner('');
            };

            const searchChange = (event: any) => {
                const query = event.target.value.toLowerCase();
                searchPropertyText.value = query;
            };

            const searchButton = () => {
                isSearchOpen.value = !isSearchOpen.value;
                if (!isSearchOpen.value) {
                    searchPropertyText.value = '';
                }
            };

            onMounted(() => {
                getPropertiesForDateRange();
            });

            watch(
                () => order.value,
                () => {
                    if (store.reservationStartDate != '' && store.reservationEndDate != '') {
                        getPropertiesForDateRange();
                    }
                }
            );

            return {
                searchOutline,
                locationSharp,
                checkmarkCircleOutline,
                closeCircleOutline,
                informationCircleOutline,
                arrowBackOutline,
                searchCircle,
                closeCircle,
                home,
                filter,
                formatDate,
                searchPropertyText,
                isSearchOpen,
                selectedPropertyId,
                selectedPropertyName,
                thumbnailUrl,
                availablePropertyList,
                AvailablePropertyListHasOwner,
                PropertyListQuotes,
                error,
                order,
                store,
                iconStyle,
                searchChange,
                searchButton,
                clearDates,
                getDisplayMoney,
                getPropertyFees,
                cancel,
                getPropertiesForDateRange,
                changeOrder,
                getDateToString,
                isSelectedProperty,
                searchPropertyMatch,
                selectProperty,
                confirm,
            };
        },
    });
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/vip.svg'
import _imports_1 from '@/assets/icons/blocklist.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-f423baa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","width":"-webkit-fill-available"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"center","margin":"5px"}
}
const _hoisted_4 = { style: {"width":"100%"} }
const _hoisted_5 = {
  key: 0,
  style: {"text-align":"center","margin":"5px"}
}
const _hoisted_6 = { style: {"width":"-webkit-fill-available"} }
const _hoisted_7 = {
  key: 0,
  style: {"text-align":"center","margin":"5px"}
}
const _hoisted_8 = { style: {"text-align":"left","margin":"auto"} }
const _hoisted_9 = {
  key: 0,
  src: _imports_0
}
const _hoisted_10 = {
  key: 1,
  src: _imports_1
}
const _hoisted_11 = {
  key: 0,
  src: _imports_0
}
const _hoisted_12 = {
  key: 1,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    ref: "modalBaseLayoutRef",
    title: "Select / Create Guest"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_list, { class: "ldx-input" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { src: _ctx.person }, null, 8, ["src"]),
                _createVNode(_component_ion_item, {
                  mode: "md",
                  lines: "full",
                  style: {"width":"-webkit-fill-available"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      label: "First Name",
                      "label-placement": "floating",
                      autocapitalize: "true",
                      modelValue: _ctx.FirstName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.FirstName) = $event)),
                      placeholder: "John"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_text, { color: "danger" }, {
              default: _withCtx(() => [
                (!_ctx.validateName(_ctx.FirstName) && _ctx.FirstName != '')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, " First Name Invalid Format "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ion_list, { class: "ldx-input" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { src: _ctx.person }, null, 8, ["src"]),
                _createVNode(_component_ion_item, {
                  mode: "md",
                  lines: "full",
                  style: {"width":"-webkit-fill-available"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      autocapitalize: "true",
                      "label-placement": "floating",
                      label: "Last Name",
                      modelValue: _ctx.LastName,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.LastName) = $event)),
                      placeholder: "Doe"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_text, { color: "danger" }, {
              default: _withCtx(() => [
                (!_ctx.validateLastName(_ctx.LastName) && _ctx.LastName != '')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Last Name Invalid Format "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ion_list, { class: "ldx-input" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { src: _ctx.mail }, null, 8, ["src"]),
              _createVNode(_component_ion_item, {
                mode: "md",
                lines: "full",
                style: {"width":"-webkit-fill-available"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.Email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.Email) = $event)),
                    "label-placement": "floating",
                    label: "Email",
                    type: "email",
                    placeholder: "JohnDoe@example.com"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_text, { color: "danger" }, {
            default: _withCtx(() => [
              (!_ctx.validateEmail(_ctx.Email) && _ctx.Email != '')
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Invalid Email "))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearGuest()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Clear")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "medium",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cancel")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    size: "medium",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (
                                _ctx.validateEmail(_ctx.Email) && _ctx.validateName(_ctx.FirstName) && _ctx.validateLastName(_ctx.LastName)
                                    ? _ctx.confirm()
                                    : _ctx.basicAlert(
                                          'Invalid Guest Information',
                                          '',
                                          'Please enter a valid guest name and email address.'
                                      )
                            ))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Accept")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", null, [
          _createVNode(_component_perfect_scrollbar, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guestList, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (
                                _ctx.isMatch(
                                    item['first_name'],
                                    item['last_name'],
                                    item['email'],
                                    _ctx.FirstName,
                                    _ctx.LastName,
                                    _ctx.Email
                                ) &&
                                item['first_name'] === _ctx.FirstName &&
                                item['last_name'] === _ctx.LastName &&
                                item['email'] === _ctx.Email
                            )
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        class: "ion-no-padding",
                        lines: "full",
                        onClick: ($event: any) => (_ctx.fillGuestInfo(index, item)),
                        key: index,
                        value: item,
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"padding-left":"15px"} }, {
                            default: _withCtx(() => [
                              _createElementVNode("strong", null, _toDisplayString(item['first_name']) + " " + _toDisplayString(item['last_name']), 1),
                              _createTextVNode(" - " + _toDisplayString(item['email']), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (item['is_vip'])
                            ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                            : _createCommentVNode("", true),
                          (item['is_blocklist'])
                            ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_icon, {
                            style: {"margin-left":"5px"},
                            color: "black",
                            icon: _ctx.checkmarkCircleOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick", "value"]))
                    : _createCommentVNode("", true),
                  (
                                _ctx.isMatch(
                                    item['first_name'],
                                    item['last_name'],
                                    item['email'],
                                    _ctx.FirstName,
                                    _ctx.LastName,
                                    _ctx.Email
                                ) &&
                                (item['first_name'] != _ctx.FirstName ||
                                    item['last_name'] != _ctx.LastName ||
                                    item['email'] != _ctx.Email)
                            )
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        class: "ion-no-padding",
                        lines: "full",
                        onClick: ($event: any) => (_ctx.fillGuestInfo(index, item)),
                        key: index,
                        value: item,
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"padding-left":"15px"} }, {
                            default: _withCtx(() => [
                              _createElementVNode("strong", null, _toDisplayString(item['first_name']) + " " + _toDisplayString(item['last_name']), 1),
                              _createTextVNode(" - " + _toDisplayString(item['email']), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (item['is_vip'])
                            ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                            : _createCommentVNode("", true),
                          (item['is_blocklist'])
                            ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "value"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 512))
}
<template>
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M31.1667 16.1967V17.5C31.1649 20.5549 30.1757 23.5275 28.3466 25.9743C26.5174 28.421 23.9463 30.211 21.0168 31.0772C18.0872 31.9434 14.9561 31.8394 12.0905 30.7807C9.22488 29.722 6.77826 27.7653 5.11552 25.2025C3.45279 22.6397 2.66303 19.6081 2.86404 16.5598C3.06504 13.5115 4.24604 10.6098 6.23089 8.28752C8.21574 5.96524 10.8981 4.34679 13.8779 3.67354C16.8578 3.00029 19.9754 3.30831 22.7658 4.55167"
            stroke="#EFAC00"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M31.1667 6.16667L17 20.3475L12.75 16.0975"
            stroke="#EFAC00"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

import { useStore } from '@/store/store';
import { format, add } from 'date-fns';
import AppAPI from '@/services/AppAPI';
import { getWidgetData } from '@/services/WidgetLoader';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { loadStorage } from './SecureStorage';
import { Capacitor } from '@capacitor/core';
import { Intercom } from '@capacitor-community/intercom';
import { widgetAccess } from '@/types/accessGroups';
import { accessToken, refreshToken } from '@/services/OAuth2';
import { PushNotifications } from '@capacitor/push-notifications';
import LodgixOAuth2 from '@/services/OAuth2';
import router from '@/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { basicAlert } from './AlertController';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { successToast } from './Toast';

export default {
    openDeviceSettings(){
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App
        });
    },
    
    isSignedIn() {
        const store = useStore();
        if (

            accessToken.value &&
            refreshToken.value
        ) {
            return true;
        } else {
            return false;
        }
    },

    clearReservationStorage() {
        const store = useStore();
        store.changeReservationFirstName('');
        store.changeReservationLastName('');
        store.changeReservationEmail('');
        store.changeReservationGuestId('');
        store.changeReservationStartDate('');
        store.changeReservationEndDate('');
        store.changeReservationSelectedProperty('');
        store.changeReservationSelectedPropertyId('');
        store.changeReservationAdults('1');
        store.changeReservationChildren('0');
        store.changeReservationPets('0');
        store.changeReservationDateEntered('');
        store.changeReservationReservationType('');
        store.changeReservationTaxExemption('');
        store.changeReservationConfirmationAmount('');
        store.changeReservationThumbnailUrl('');
        store.changeReservationTotalWithDeposits('');
        store.changeReservationInvoiceId('');
        store.changeReservationSetupIntentClientSecret('');
        store.changeReservationStripePublishableKey('');
        store.changeReservationStripeAccount('');
        store.changeReservationStripeIsCard(false);
        store.changeReservationPropertyListQuotes('');
        store.changeReservationPropertyListHasOwner('');
        store.changeReservationBillingState('');
        store.changeReservationBillingAddress('');
        store.changeReservationBillingCity('');
        store.changeReservationBillingZip('');
        store.changeReservationBillingCountry('');
        store.changeReservationBillingCountryList('');
        store.changeReservationBillingStateList('');
        store.changeIsQuoteOnly(false);
    },

    getNextDate(dateString: any) {
        const tmpDate = add(new Date(dateString), { days: 1 });
        const tmpDateString = format(tmpDate, 'MM/dd/yyyy');
        return tmpDateString;
    },

    getFutureDay(dateString: any, daysAhead: number) {
        const date = add(new Date(dateString), { days: daysAhead });

        return {
            month: format(date, 'M'),
            day: format(date, 'd'),
            year: format(date, 'yyyy'),
            name: format(date, 'EE'),
        };
    },

    validateEmail(email: string) {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    },

    validateFirstName(name: string) {
        const nameRegex = /^[A-Za-z\s&'-]+$/;
        return nameRegex.test(name);
    },

    validateLastName(name: string) {
        const nameRegex = /^[A-Za-z]+(?:,[ ]?(?:jr\.|sr\.|Sr\.|Jr\.|Sr|Jr|sr|jr|III|IV|V|VI|VII|VIII|IX|X)?)?$|^[\w\s]+(?:[ ]?(?:jr\.|sr\.|Sr\.|Jr\.|Sr|Jr|sr|jr|III|IV|V|VI|VII|VIII|IX|X)?)$/;
        return nameRegex.test(name);
    },

    async updatePushNotificationSystem() {
        const store = useStore();
        if (accessToken.value) {
            const value = await AppAPI.getPushNotifications().catch((error) => {
                console.log(error);
            });
            if (value) {
                store.setPushNotificationList(value.data);
                const count = store.PushNotificationList.length;
                store.changepushNotificationUnreadCount(count);
            }
        }
    },

    async getUserInfo(forceUpdate = false) {
        const store = useStore();
        if ((accessToken.value && store.SubscriberId == '') || forceUpdate) {
            const userData = await AppAPI.getUserInformation().catch((error) => {
                console.log(error);
            });
            if (userData) {
                store.changeName(userData.data.first_name);
                store.changeLastName(userData.data.last_name);
                store.changeEmail(userData.data.email);
                store.changeCurrency(userData.data.currency.code);
                store.changeCurrencySymbol(userData.data.currency.symbol);
                store.changeId(userData.data.id);
                store.changeSubId(userData.data.subscriber_id);
                store.changeCompanyEmail(userData.data.company.email);
                store.changeCompanyName(userData.data.company.title);
                store.changeCompanyLogo(userData.data.website.logo_url);
                store.changeCompanyAddress(userData.data.company.address);
                store.changeCompanyWebsite(userData.data.company.website);
                store.changeCompanyPhone(userData.data.company.phone);
                store.changeChatUserId(userData.data.chat_user_id);
                store.changeWebsocketToken(userData.data.websocket_token);
                store.changeWebsocketUrl(userData.data.websocket_url);
                store.changeCalendarTapeOptions(userData.data.calendar_tape_options);
                store.changeInboxPermissions(userData.data.permissions);
            }
        }
    },

    async loadWidgetData() {
        if (
            widgetAccess()
        ) {
            const widgetData = await getWidgetData().catch((error) => {
                console.log(error);
            });
        }
    },

    async loadTeamMembers() {
        const store = useStore();
        const teamMembers = await AppAPI.getTeamMembers().catch((error) => {
            console.log(error);
        });
        if (teamMembers) {
            console.log(teamMembers.data)
            //store.changeTeamMembers(teamMembers.data);
        }
    },

    //load in saved data from secure storage and get basic user info
    async setupProcess() {
        const store = useStore();
        await loadStorage();
        await this.getUserInfo();
        await this.loadWidgetData();
        this.updatePushNotificationSystem();
        store.changeFirstOpenApp('1');
    },

    async loadIntercom() {
        const store = useStore();
        Intercom.registerIdentifiedUser({ email: store.UserEmail });
        const hash = await AppAPI.getIntercomHash(Capacitor.getPlatform()).catch((error) => {
            console.log(error);
        });
        if (hash) {
            Intercom.setUserHash({ hmac: hash.data.hmac });
            Intercom.updateUser({
                name: store.UserFirstName + ' ' + store.UserLastName,
                customAttributes: { account_type: store.LoginType },
            });
        }
    },

    async openChat(conversationId: any, messageId=null) {
        const store = useStore();
        let url = '/unifiedInbox/subscriber/0/inbox/0/conversation/' + conversationId;
        if (messageId) {
            url += '?message_id=' + messageId;
        }
        store.changeActivePage(url);
        window.location.href = url;
        //router.replace(url);
    },

    async transitionClear(page:string) {
        const store = useStore();
        
        if (Capacitor.getPlatform() != 'web') {
            PushNotifications.removeAllDeliveredNotifications();
        }
        this.clearReservationStorage();

        if (page == '/signout') {
            await LodgixOAuth2.logout();
            return;
        }
        if (page == '/support') {
            await Intercom.displayMessenger();
            return;
        }

        store.changeActivePage(page);

        if (page == '/home') {
            router.replace(page);
        } else if (page == '/calendarPage') {
            window.location.href = page;
        } else {
            router.replace(page);
        }
    },

    async uuid() {
        return 'xxxx-xxxx'.replace(/[x]/g, (c) => {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    async clearSecureStorage() {
        const keys = ['loginType', 'notificationList', 'lang'];
        const values = ['', '', 'en'];
        for (let i = 0; i < keys.length; i++) {
            await SecureStoragePlugin.set({ key: keys[i], value: values[i] });
        }
    },

    async clearLocalStorage() {
        const store = useStore();

        store.changeLoginType('');
        store.changeName('');
        store.changeLastName('');

        store.changeEmail('');
        store.changeCurrency('');
        store.changeCurrencySymbol('');
        store.changeReservationFirstName('');
        store.changeReservationLastName('');
        store.changeReservationEmail('');
        store.changeReservationStartDate('');
        store.changeReservationEndDate('');
        store.changeReservationSelectedProperty('');
        store.changeReservationSelectedPropertyId('');
        store.changeReservationAdults('');
        store.changeReservationChildren('');
        store.changeReservationPets('');
        store.changeReservationDateEntered('');
        store.changeReservationReservationType('');
        store.changeReservationTaxExemption('');
        store.changeReservationConfirmationAmount('');
        store.changeCompanyEmail('');
        store.changeCompanyName('');
        store.changeCompanyLogo('');
        store.changeCompanyAddress('');
        store.changeCompanyWebsite('');
        store.changeCompanyPhone('');
        store.changePushNotificationToken('');
        store.changeLoginType('');
        store.changeId('');
        store.changeSubId('');
        store.changeLang('en');

        store.changeDepartureDataCount(0);
        store.changeDepartureDataArray('');
        store.changeArrivalDataCount(0);
        store.changeArrivalDataArray('');
        store.changePendingDataCount(0);
        store.changePendingDataArray('');
        store.changeOccupancyAmount('');
        store.changeArrivalAmount('');
        store.changeDepartureAmount('');
        store.changePendingAmount('');
        store.changePushNotificationOpenId('');
        store.changeCalendarTapeOptions('');

        store.changeChatUserId('');
        store.changeWebsocketToken('');
        store.changeWebsocketUrl('');

        store.changeScope('');

        store.changeWorkOrderList([]);
        store.changeWorkOrderListTotal(0);
        store.changeWorkOrderJobTypes([]);
        store.changeWorkOrderVendors([]);
        store.changeWorkOrderExpenseAccounts([]);
        store.changeWorkOrderFormOptions([]);
        store.changeWorkOrderFormProperties([]);
        store.changeWorkOrderTeamMembers([]);
        store.changeWorkOrderPermissions({
            can_add: false,
            can_delete: false,
            has_add_access: false,
            has_base_work_order_access: false,
            has_config_access: false,
            has_delete_access: false,
            has_modify_access: false,
            is_close_disabled: false,
            is_modify_disabled: false,
            is_subscriber: false,
        });
    },

    async openEmail(email:any) {
        window.open('mailto:' + email);
    },

    async openPhone(phone:any) {
        window.open('tel:' + phone);
    },

    async openText(phone:any) {
        window.open('sms:' + phone);
    },

    async copyText(text:any) {
        navigator.clipboard.writeText(text);
        successToast('Copied to clipboard');
    }
};

<template>
    <input
        ref="inputRef"
        type="text"
        class="currency-input"
        :style="`text-align: ${textAlignment}; border: 0; background:white;`"
        :placeholder="placeholder"
    />
</template>

<script>
    import { useCurrencyInput } from 'vue-currency-input';

    export default {
        name: 'CurrencyInput',
        props: {
            modelValue: Number,
            options: Object,
            placeholder: {
                type: String,
                default: '',
            },
            textAlignment: {
                type: String,
                default: 'end',
            },
        },
        setup(props) {
            const { inputRef } = useCurrencyInput(props.options);

            return { inputRef };
        },
    };
</script>

<style scoped>
    .currency-input {
        border: 0;
    }
    .currency-input:focus {
        outline: none;
    }
</style>

<template>
    <div style="padding-bottom: 15px">
        <ion-list>
            <ion-item lines="none" class="ion-no-padding">
                <ion-card-title style="font-size: 20px">{{ TitleLabel }}</ion-card-title>
            </ion-item>
        </ion-list>

        <div>
            <vc-date-picker
                class="custom-calendar"
                style="width: 100%"
                v-if="!isReset"
                is-range
                :min-date="new Date()"
                v-model="dateRange"
            ></vc-date-picker>
        </div>
        <ion-button
            v-if="store.reservationEndDate != ''"
            color="danger"
            style="--border-radius: 10px; width: 100%; padding-top: 10px"
            @click="clearDates"
            >Clear Dates</ion-button
        >
    </div>
</template>

<script lang="ts">
    import { useStore } from '@/store/store';
    import { add, format } from 'date-fns';
    import { optionsOutline, calendarOutline } from 'ionicons/icons';
    import { computed, defineComponent } from 'vue';

    export default defineComponent({
        props: ['TitleLabel', 'StartDateLabel', 'EndDateLabel'],
        components: {},
        setup() {
            const store = useStore();
            var dateTomorrow = add(new Date(), { days: 1 });
            var dateTomorrowString = format(dateTomorrow, 'yyyy-MM-dd');

            return {
                dateTomorrowString,
                optionsOutline,
                calendarOutline,
                store,
            };
        },
        methods: {
            clearDates() {
                this.isReset = true;
                this.$nextTick(() => {
                    this.isReset = false;
                    this.store.changeReservationStartDate('');
                    this.store.changeReservationEndDate('');
                    this.selectedPropertyId = '';
                    this.dateRange = {
                        start: '',
                        end: '',
                    };
                });
                this.dateRange = {
                    start: '',
                    end: '',
                };
                this.store.changeReservationStartDate('');
                this.store.changeReservationEndDate('');
                this.store.changeReservationSelectedPropertyId('');
                this.store.changeReservationSelectedProperty('');
                this.store.changeReservationPropertyListQuotes('');
            },
            loadDates() {
                this.dateRange[0] = this.store.reservationStartDate;
                this.dateRange[1] = this.store.reservationEndDate;
            },
        },
        watch: {
            'dateRange.end': function () {
                if (this.dateRange.end != '') {
                    this.store.changeReservationStartDate(this.dateRange.start);
                    this.store.changeReservationEndDate(this.dateRange.end);
                }
            },
        },
        mounted() {
            this.loadDates();
            this.dateRange = {
                start: this.store.reservationStartDate,
                end: this.store.reservationEndDate,
            };
        },
        data() {
            return {
                dateRange: {
                    start: '', //this.store.reservationStartDate,
                    end: '', //this.store.reservationEndDate,
                },
                isReset: false,
                selectedPropertyId: '',
            };
        },
    });
</script>

<style>
    .custom-calendar .vc-header .vc-arrow {
        color: #000;
        background: white;
    }

    .custom-calendar .vc-header .vc-title {
        color: #000;
        background: white;
    }
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Edit Device" }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "danger",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Close")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _ctx.save
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Save")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ion_list, { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: "Device Name",
                "label-placement": "floating",
                modelValue: _ctx.deviceRef.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deviceRef.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: "Device Type",
                readonly: "",
                modelValue: _ctx.deviceRef.type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deviceRef.type) = $event)),
                "label-placement": "floating"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.deviceRef.active,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.deviceRef.active) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Push Notifications")
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_ion_chip, {
                style: {"margin-left":"10px"},
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.basicAlert('Notice', '', _ctx.noticeText)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("?")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
<template>
    <modal-base-layout title="Edit Device">
        <ion-list lines="full">
            <ion-item>
                <ion-input label="Device Name" label-placement="floating" v-model="deviceRef.name"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input label="Device Type" readonly v-model="deviceRef.type" label-placement="floating"></ion-input>
            </ion-item>

            <ion-item>
                <ion-toggle v-model="deviceRef.active">Push Notifications</ion-toggle>
                <ion-chip style="margin-left: 10px" @click="basicAlert('Notice', '', noticeText)">?</ion-chip>
            </ion-item>
        </ion-list>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="cancel">Close</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="save">Save</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { basicAlert } from '@/services/AlertController';
    import AppAPI from '@/services/AppAPI';
    import { useStore } from '@/store/store';
    import { modalController } from '@ionic/vue';
    import { defineComponent, ref, onMounted, watch } from 'vue';

    export default defineComponent({
        components: {},
        props: {
            device: {
                type: Object,
                default: () => {
                    return {};
                },
            },
        },
        setup(props) {
            const store = useStore();
            const deviceRef = ref(props.device);
            const noticeText = ref(
                'Make sure to have push notification permissions always enabled on the device itself. iOS and Android require permission within the device settings for the Lodgix App. Most web browsers require user permissions within the browser settings before allowing push notifications from Lodgix.com. App and web browser permissions is seperate from Lodgix.'
            );

            const cancel = () => {
                deviceRef.value = props.device;
                return modalController.dismiss(null, 'cancel');
            };
            const save = async () => {
                await AppAPI.createDevice(
                    deviceRef.value.name,
                    deviceRef.value.registration_id,
                    deviceRef.value.device_id,
                    deviceRef.value.type,
                    deviceRef.value.active
                );
                return modalController.dismiss(null, 'cancel');
            };

            onMounted(() => {
                deviceRef.value = props.device;
            });

            return {
                cancel,
                save,
                store,
                deviceRef,
                AppAPI,
                noticeText,
                basicAlert,
            };
        },
    });
</script>

<template>
    <base-layout page-title="Create Block">
        <div style="max-width: var(--max-width); text-align: left; margin: auto">
            <ion-card v-if="isSubmitted">
                <ion-card-header style="background: rgb(32, 70, 155); margin: 0px; padding-bottom: 15px">
                    <p style="font-weight: 700; font-size: large; margin: 0px; color: white">Successfully Submitted</p>
                </ion-card-header>
                <ion-card-content style="margin-top: 10px">
                    <ion-label
                        >The block has been successfully submitted. You may either close the app or go back to the home
                        page.</ion-label
                    >
                    <ion-button
                        @click="clearReservationSave()"
                        color="primary"
                        style="
                            margin-top: 15px;
                            width: -webkit-fill-available;
                            --border-radius: 10px;
                            margin-left: 0px;
                            margin-right: 0px;
                        "
                        href="/home"
                        >Home</ion-button
                    >
                </ion-card-content>
            </ion-card>

            <div v-if="!isSubmitted">
                <div
                    style="
                        background-color: white;
                        margin: 15px;
                        padding: 20px;
                        border-radius: 10px;
                        box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 10%);
                        border-color: black;
                        border-width: 1px;
                    "
                >
                    <DateSelectorComponent v-bind="dateProps"></DateSelectorComponent>

                    <!--
                        Select Property
                    -->
                    <PropertyComponent v-bind="isMinimal"></PropertyComponent>

                    <ion-card-header class="ldx-section-start">
                        <ion-card-title position="stacked">Block Reason</ion-card-title>
                    </ion-card-header>
                    <ion-list class="ldx-block-form">
                        <!--
                            Block Text Area
                        -->
                        <ion-item lines="none">
                            <ion-textarea v-model="ReasonText" placeholder="Enter text here..."></ion-textarea>
                        </ion-item>

                        <!--
                            Owner Block
                        -->
                        <div v-if="isHasOwner()">
                            <ion-item lines="none">
                                <ion-checkbox v-model="HasOwnerCheckBox">Designate as owner block</ion-checkbox>
                            </ion-item>
                            <ion-label text-wrap>Block will appear on housekeeping and arrival reports</ion-label>
                        </div>
                    </ion-list>

                    <!--
                        Submit Button
                    -->
                    <ion-button
                        style="margin-top: 15px; width: -webkit-fill-available; --border-radius: 10px"
                        @click="submitButton()"
                        v-if="store.reservationSelectedPropertyId != '' && store.reservationSelectedPropertyId != null"
                        color="primary"
                    >
                        Submit
                    </ion-button>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import CommonFunctions from '@/services/AppFunctions';
    import AppAPI from '@/services/AppAPI';
    import { format } from 'date-fns';
    //import {showLoading, hideLoading } from '../services/Loading'
    import DateSelectorComponent from '@/components/reservation/components/DateSelectorComponent.vue';
    import PropertyComponent from '@/components/reservation/components/PropertyComponent.vue';
    import { useStore } from '@/store/store';

    export default defineComponent({
        name: 'CalendarPage',
        components: {
            DateSelectorComponent,
            PropertyComponent,
        },
        setup() {
            const ionRouter = useIonRouter();
            const store = useStore();
            const ReasonText = ref('');
            const HasOwnerCheckBox = ref(false);
            const isSubmitted = ref(false);
            const isMinimal = ref({ isMinimal: true });
            const dateProps = ref({
                TitleLabel: 'Select Block Dates',
                StartDateLabel: 'Start Date',
                EndDateLabel: 'End Date',
            });

            const isHasOwner = () => {
                for (var i = 0; i < store.reservationPropertyListHasOwner.length; i++) {
                    if (store.reservationSelectedPropertyId == store.reservationPropertyListHasOwner[i]) {
                        return true;
                    }
                }
                return false;
            };

            const submitButton = () => {
                var fromDateString = format(new Date(store.reservationStartDate), 'yyyy-MM-dd');
                var toDateString = format(new Date(store.reservationEndDate), 'yyyy-MM-dd');

                var response = AppAPI.sendBlock(
                    store.reservationSelectedPropertyId,
                    fromDateString,
                    toDateString,
                    ReasonText.value,
                    HasOwnerCheckBox.value
                );
                response
                    .then((value) => {
                        if (value.data == '') {
                            isSubmitted.value = true;
                        }
                    })
                    .catch(async () => {
                        isSubmitted.value = false;
                    });
            };

            const clearReservationSave = () => {
                store.changeActivePage('/home');
                CommonFunctions.clearReservationStorage();
                ReasonText.value = '';
                HasOwnerCheckBox.value = false;
            };

            onMounted(() => {
                ReasonText.value = '';
                HasOwnerCheckBox.value = false;
            });

            return {
                ionRouter,
                ReasonText,
                HasOwnerCheckBox,
                isSubmitted,
                isMinimal,
                dateProps,
                store,
                isHasOwner,
                submitButton,
                clearReservationSave,
            };
        },
    });
</script>

<style scoped>
    .ldx-section-start {
        padding-top: 0;
        padding-left: 0;
    }

    .ldx-section-start ion-card-title {
        font-size: 20px;
    }
    .ldx-block-form ion-item::part(native) {
        background: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-medium-tint);
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.75rem;
    }
    .ldx-block-form ion-label {
        margin: 0;
    }

    .ldx-block-form ion-icon {
        font-size: 14px;
    }

    .ldx-block-form .select-icon {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-block-form ion-select::part(placeholder),
    .ldx-block-form ion-select::part(text),
    .ldx-block-form ion-select::part(icon) {
        color: #000000;
        opacity: 1;
    }
    .ldx-block-form ion-select::part(icon) {
        right: 1.5rem;
        position: absolute;
    }

    .ldx-block-form ion-icon.ldx-alt-icon {
        background: var(--ion-color-primary);
        color: #ffffff;
        padding: 0.5rem;
        font-size: 15px;
        border-radius: 5px;
        margin: 0;
    }
</style>

<template>
    <modal-base-layout ref="modalBaseLayoutRef" title="Filters">
        <ion-list lines="full" v-if="filters">
            <ion-item>
                <ion-select
                    class="filter-button"
                    v-model="filters.timeframe"
                    placeholder="Select Timeframe"
                    label="Timeframe"
                >
                    <ion-select-option value="Yesterday">Yesterday</ion-select-option>
                    <ion-select-option value="Today">Today</ion-select-option>
                    <ion-select-option value="Last Week">Last Week</ion-select-option>
                    <ion-select-option value="This Week">This Week</ion-select-option>
                    <ion-select-option value="Last Month">Last Month</ion-select-option>
                    <ion-select-option value="This Month">This Month</ion-select-option>
                    <ion-select-option value="Last Year">Last Year</ion-select-option>
                    <ion-select-option value="This Year">This Year</ion-select-option>
                    <ion-select-option value="Future">Future</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-select class="filter-button" v-model="filters.type" placeholder="Select Type" label="Type">
                    <ion-select-option value="">Reservations</ion-select-option>
                    <ion-select-option value="BLOCKED">Blocks</ion-select-option>
                </ion-select>
            </ion-item>

            <ion-item>
                <ion-select class="filter-button" v-model="filters.status" placeholder="Select Status" label="Status">
                    <ion-select-option value="">All Statuses</ion-select-option>
                    <ion-select-option value="PAID">Paid/Paid In Full</ion-select-option>
                    <ion-select-option value="PAIDDEPOSIT">Confirmed</ion-select-option>
                    <ion-select-option value="NOTPAID">Unconfirmed</ion-select-option>
                    <ion-select-option value="CANCELLED">Cancelled</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-list>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col style="text-align: center">
                        <ion-label>Filter changes are applied automatically.</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="resetFilters()"
                            >Reset Filters</ion-button
                        >
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="modalBaseLayoutRef.confirm()"
                            >Close</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { defineComponent, onMounted, ref, watch } from 'vue';

    export default defineComponent({
        components: {},
        props: {
            currentFilters: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const modalBaseLayoutRef = ref();
            const filters = ref(props.currentFilters);

            const resetFilters = () => {
                filters.value.timeframe = 'Future';
                filters.value.type = '';
                filters.value.status = '';
            };

            return {
                modalBaseLayoutRef,
                filters,
                resetFilters,
            };
        },
    });
</script>

<style scoped></style>

<template>
    <ion-content style="--background: white">
        <div style="position: fixed; width: 100%">
            <div class="ldx-login-logo ion-text-center">
                <img src="@/assets/img/lodgix-white.svg" width="188" />
            </div>

            <div style="margin-left: 30px; margin-right: 30px">
                <ion-card
                    mode="md"
                    class="ion-text-center ldx-welcome-card"
                    style="max-width: var(--max-width); margin-left: auto; margin-right: auto"
                >
                    <ion-card-header>
                        <ion-card-title>
                            <h1>Welcome</h1>
                        </ion-card-title>
                    </ion-card-header>

                    <ion-card-content>
                        <ion-button @click="login()" style="width: 100%">LOGIN</ion-button>
                    </ion-card-content>
                </ion-card>
            </div>
        </div>

        <div class="ldx-bottom-content" style="position: fixed; bottom: 0px; padding-bottom: 35px; width: 100%">
            <ion-label
                style="display: flex; justify-content: center; font-weight: 200; font-size: medium"
                @click="sendEmail()"
                >© {{ date }} | support@lodgix.com | {{ appVersion }}</ion-label
            >
        </div>
    </ion-content>
</template>

<script lang="ts">
    import { defineComponent } from '@vue/runtime-core';
    import { format } from 'date-fns';
    import LodgixOAuth2 from '@/services/OAuth2';
    import { Capacitor } from '@capacitor/core';
    import { errorToast } from '@/services/Toast';
    import { SplashScreen } from '@capacitor/splash-screen';
    import { ref } from 'vue';

    export default defineComponent({
        components: {},
        setup() {
            var date = format(new Date(), 'yyyy');
            const appVersion = ref(process.env.VUE_APP_VERSION);

            const login = async () => {
                if (Capacitor.getPlatform() != 'web') {
                    await SplashScreen.show({
                        autoHide: false,
                    });
                }

                try {
                    const value = await LodgixOAuth2.authenticateWithLodgix();

                    if (value === 'USER_CANCELLED') {
                        errorToast('Login Cancelled.');
                    } else if (value === 'BAD_ACCOUNT_LOGIN') {
                        await LodgixOAuth2.logout();
                        errorToast('Account Not Supported. Please try again.');
                    }
                } catch (error) {
                    if (Capacitor.getPlatform() !== 'web') {
                        await LodgixOAuth2.logout();
                        errorToast('Error logging in. Please try again.');
                    }
                }

                if (Capacitor.getPlatform() != 'web') {
                    await SplashScreen.hide();
                }
            };

            const sendEmail = () => {
                window.open('mailto:support@lodgix.com?subject=Mobile App Support');
            };

            return {
                date,
                login,
                sendEmail,
                appVersion,
            };
        },
    });
</script>

<style scoped>
    ion-button {
        --background: var(--ion-color-secondary);
        --background-hover: var(--ion-color-secondary-tint);
        --background-activated: var(--ion-color-secondary-tint);
        --border-radius: 50px;
        --padding-top: 12px;
        --padding-bottom: 12px;
        min-height: 40px;
    }

    #container {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .ldx-login-logo {
        min-height: 50vh;
        max-height: 50vh;
        background-image: url('@/assets/img/login-background.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 155px;
        animation: showImage 1s;
    }

    .ldx-welcome-card {
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;
        z-index: 2;
        border-radius: 30px;
        margin: -155px 32px 32px 32px;
        box-shadow: rgba(13, 72, 161, 0.5) 0px 4px 50px 0px;
        animation: showLogin 1s;
    }

    .ldx-login-content {
        position: relative;
        --background: white;
    }

    .ldx-bottom-content {
        animation: showBottom 1s;
    }

    @keyframes showImage {
        0% {
            opacity: 0;
            transform: translateY(-500px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes showBottom {
        0% {
            opacity: 0;
            transform: translateY(100px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes showLogin {
        0% {
            opacity: 0;
            transform: translateX(-300px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>
